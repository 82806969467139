import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';


const AddUser = (props) => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
 
  const [user, setUser] = useState({
      id: null,
      first_name: "",
      last_name: "",
      from_date: "",
      other_names: "",
      gender: "",
      date_of_birth: new Date().toISOString().split('T')[0] || '',
      email: "",
      phone_number: "",
      station_name: "",
      region: 1,
      division: "",
      password: "",
      user_role: "",

      submitted: false
    });
  


  const saveUser = () => {
 
    const config = {
      headers: {
        timeout: 30000,
        "Content-Type": "application/json"
      }
    }
    // UsersDataService.create(data)
    axiosInstance.post('/api/users', user, config)
      .then(response => {
        setUser({
          ...response.data,
          submitted: true
        });

        toast.success("User Created successfully!", {
          onClose: () => navigate('/user_list') // Redirect to the home page when the toast is closed
        });
       // console.log(response.data);
      })
      .catch(e => {
        toast.error("Error creating user."); // Display an error toast
        if (e.code === 'ECONNABORTED') {
          console.log('Request took longer than 5 seconds and was aborted');
        } else {
          console.log(e);
        }
      });
  }

   // Define a function to handle the onChange for each field
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  }; 

    return (
      <div className="submit-form">
       <ToastContainer />
        <div>
              <h1>Register User</h1>
              <Row>
                <Col>
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={user.first_name}
                      onChange={handleInputChange}
                      name="first_name"
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      value={user.last_name}
                      onChange={handleInputChange}
                      name="last_name"
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label htmlFor="other_names">Other Names</label>
                    <input
                      type="text"
                      className="form-control"
                      id="other_names"
                      value={user.other_names}
                      onChange={handleInputChange}
                      name="other_names"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="form-group">
                    <label htmlFor="gender">Gender</label>
                    <select
                      className="form-control"
                      id="gender"
                      value={user.gender}
                      onChange={handleInputChange}
                      name="gender"
                    >
                      <option>----Please select an option----</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={user.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  </div>
                </Col>
              </Row>

              <div className="form-group">
                <label htmlFor="phone_number">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone_number"
                  value={user.phone_number}
                  onChange={handleInputChange}
                  name="phone_number"
                />
              </div>

              <div className="form-group">
                <label htmlFor="station_name">Station</label>
                <input
                  type="text"
                  className="form-control"
                  id="station_name"
                  value={user.station_name}
                  onChange={handleInputChange}
                  name="station_name"
                />
              </div>

              <Row>
              <Col>
             
            <div className="form-group">
              <label htmlFor="division">Division</label>
              <select
                className="form-control"
                id="division"
                value={user.division}
                onChange={handleInputChange}
                name="division"
              >
                <option value={1}>Division 1</option>
                <option value={2}>Division 2</option>
                <option value={3}>Division 3</option>
                <option value={'4a'}>Division 4a</option>
                <option value={'4b'}>Division 4b</option>
                <option value={'4c'}>Division 4c</option>
                <option value={5}>Division 5</option>
                <option value={6}>Division 6</option>
                <option value={7}>Division 7</option>
                <option value={8}>Division 8</option>
                <option value={9}>Division 9</option>
                <option value={10}>Division 10</option>
              </select>
            </div> 
                </Col>
                <Col>
                <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                id="region"
                value={user.region}
                onChange={handleInputChange}
                name="region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
                </Col>


              </Row>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={user.password}
                  onChange={handleInputChange}
                  name="password"
                />
              </div>

              <div className="form-group">
                <label htmlFor="user_role">User Role</label>

                <select
                className="form-control"
                id="user_role"
                value={user.user_role}
                onChange={handleInputChange}
                name="user_role"
              >
                <option >Choose a Role</option>
                <option >Admin</option>
                <option >Normal User</option>
                <option >Data Entry</option>
              </select>
              </div>

              <button onClick={saveUser} className="btn btn-success">
                Submit
              </button>
            </div>
      </div>
    );
  
}
export default AddUser;
