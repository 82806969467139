import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


import EditUser from "./edit-user.component";
import  ResetPassword from "./password-reset.component";
import axiosInstance from '../../services/axiosInstance';

const UserTableRow = props => {
  const {
    user_id,
    first_name,
    last_name,
    other_names,
    gender,
    email,
    phone_number,
    station_name,
    division,
    region,
    user_role
  } = props.obj;

  const deleteUser = () => {
    axiosInstance
      .delete("/api/users/" + user_id)
      .then(res => {
        if (res.status === 200) {
          console.log("User successfully deleted");
          window.location.reload();
        } else Promise.reject();
      })
      .catch(err => console.log("Something went wrong"));
  };

  return (
    <tr>
      <td>
        {first_name}
      </td>
      <td>
        {last_name}
      </td>
      <td>
        {other_names}
      </td>
      <td>
        {gender}
      </td>
      <td>
        {email}
      </td>
      <td>
        {phone_number}
      </td>
      <td>
        {station_name}
      </td>
      <td>
        {division}
      </td>
      <td>
      {region}
      </td>
      <td>
        {user_role}
      </td>
      <td>
        <Link
          className="edit-link"
          to={"/edit-user/" + user_id}
          element={<EditUser />}
        >
          Edit
        </Link>
        <br />
        <br />

        <Link
          className="edit-link"
          to={"/password-reset/" + user_id}
          element={<ResetPassword />}
        >
          Reset Password
        </Link>
        <br />
        <br />

        <Button onClick={deleteUser} size="sm" variant="danger">
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default UserTableRow;
