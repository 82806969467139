import { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosInstance';

const AuthContext = createContext({
   isLoggedIn: false,
 userEmail: null, // Add state variable for email
 userId: null,
 userName: null, // Add state variable for name
 userRegion: null,
 stationName: null,
 userRole: null,
  login: () => {},
  logout: () => {},
});

// const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(null); // Add state variable for email
  const [userId, setUserId] = useState(null); // Add state variable for id
  const [userName, setUserName] = useState(null); // Add state variable for name
  const [userRegion, setUserRegion] = useState(null); // Add state variable for name
  const [stationName, setStationName] = useState(null); // Add state variable for station name
  const [userRole, setUserRole] = useState(null); // Add state variable for role

  useEffect(() => {
    // Check if the user is already logged in on component mount
    const token = localStorage.getItem('token');
    const storedEmail = localStorage.getItem('email'); // retrieve email from local storage
    if (token && storedEmail) {
      setIsLoggedIn(true);
      setUserEmail(storedEmail); // set email from local storage
      // Fetch the user data from the server
      axiosInstance.get(`/api/users/userByEmail/${storedEmail}`)
        .then(response => {
          setUserId(response.data.user_id); // Set userName from the server response
          setUserName(response.data.first_name); // Set userName from the server response
          setUserRegion(response.data.region); // Set userRegion from the server response
          setStationName(response.data.station_name); // Set userName from the server response
          setUserRole(response.data.user_role); // Set userRole from the server response
        })
        .catch(error => console.error(error));
    }

  }, []);

  const login = async(token, email) => {
    localStorage.setItem('token', token);
    localStorage.setItem('email', email); // Store email to local storage
  
     try {
    const response = await axiosInstance.get(`/api/users/userByEmail/${email}`);
    setIsLoggedIn(true);
    // setUserEmail(email);
    setUserEmail(response.data.email);
    setUserId(response.data.user_id);
    setUserName(response.data.first_name);
    setUserRegion(response.data.region); 
    setStationName(response.data.station_name);
    setUserRole(response.data.user_role);
    // console.log(`Logged in as ${response.data.first_name}`);
     console.log(`Logged in as ${response.data.user_id}`);
  } catch (error) {
    console.error(error);
  }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
   setUserEmail(null);
   setUserRole(null);
   setUserId(null);
   setUserName(null);
   setUserRegion(null);
   setStationName(null);
  };

  const value = {
    isLoggedIn,
   userEmail, // Add the email to the context value
   userId,
    userName, // Add the name to the context value
    userRegion,
    stationName,
    userRole,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
