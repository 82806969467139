import React, { useState, useEffect } from "react";
import { Table, Form  } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import StationTableRow from "./StationTableRow";
import AddStation from "./add-station.component";
import ReactPaginate from 'react-paginate';
import './../paginate.css';

import axiosInstance from '../../services/axiosInstance';

import { useAuth } from '../Context/AuthContext'; // Import useAuth 

const StationList = () => {
  const [stations, setStations] = useState([]);
  const { isLoggedIn } = useAuth(); // Get isLoggedIn and logout from the AuthContext
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(5); // the number of page numbers to show at a time

  useEffect(() => {
    axiosInstance
      .get("/api/stations")
      .then(({ data }) => {
        setStations(data);
        //console.log(data);  // Print data here for debugging
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const filteredStations = stations.filter(station => {
    const name = station.station_name ? station.station_name.toLowerCase().trim() : "";
    const division = station.div_id ? station.div_id.toLowerCase().trim() : "";
    const region = station.reg_id ? station.reg_id.toString() : ""; // Convert to string if it's a number
    const searchTermTrimmed = searchTerm.toLowerCase().trim();
  
    return name.includes(searchTermTrimmed) ||
           division.includes(searchTermTrimmed) ||
           region.includes(searchTermTrimmed);
  });
  
  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredStations.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <StationTableRow obj={res} key={i} />;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredStations.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  } 


  return (
    <Container>
      <h1>Station List</h1>
   
      {isLoggedIn ? (
        <Button variant="secondary">
        <Link to={"/add-station"} element={<AddStation />} className="nav-link">
          Add New Station
        </Link>
      </Button>
      ):(
        <p></p>
      )
     
      }
      <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Station Name</th>
            <th>Location</th>
            <th>Phone Number</th>
            <th>Division</th>
            <th>Region</th>
            {isLoggedIn ? (
              <th>Action</th>
            ):(<p></p>)}
           
          </tr>
        </thead>
        <tbody>
          {DataTable()}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredStations.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />
     
    </Container>
  );
};

export default StationList;
