import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import ComplimentTableRow from "./ComplimentTableRow";
import ReactPaginate from 'react-paginate';
import './../paginate.css';
import moment from 'moment';

import axiosInstance from '../../services/axiosInstance';

const ComplimentList = () => {
  const [compliment, setCompliment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(5); // the number of page numbers to show at a time


  useEffect(() => {
    axiosInstance
    .get("/api/compliment")
    .then(({ data }) => {
      // Assuming the data has a 'createdAt' field in a standard format
      const sortedData = data.sort((a, b) => {
        const dateA = moment(a.createdAt); // parse createdAt field with moment
        const dateB = moment(b.createdAt);

        return dateB.valueOf() - dateA.valueOf(); // sort in descending order
      });

      setCompliment(sortedData);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  const filteredCompliment = compliment.filter(compliment => {
    const fullName = ` ${compliment.first_name}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredCompliment.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <ComplimentTableRow obj={res} key={i} />;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredCompliment.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  } 

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }

  return (
    <Container>
      <h1>Compliment List</h1>
      <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Other Names</th>
            <th>Gender</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Street Address</th>
            <th>Village</th>
            <th>Region</th>
            <th>Name/s of Police</th>
            <th>Rank Description</th>
            <th>Other means of Id</th>
            <th>Vehicle Number</th>
            <th>Attached Station</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {DataTable()}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredCompliment.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />

    </Container>
  );
};

export default ComplimentList;
