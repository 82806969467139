import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

const AddCompliment = (props) => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const [compliment, setCompliment] = useState({
      id: null,
      first_name: "",
      last_name: "",
      other_names: "",
      gender: "",
      phone_number: "",
      email: "",
      street_address: "",
      village: "",
      region:1,
      police_name: "",
      rank_or_desc: "",
      other_id: "",
      vehicle_no: "",
      attached_station: "",
      remarks: "",

      submitted: false
    });
  

    const sectionStyle = {
      marginBottom: 40,
     };

  const saveCompliment = () => {
  
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    //ComplaintsDataService.create(data)
    axiosInstance.post('/api/compliment', compliment, config)
      .then(response => {
          setCompliment({
          ...response.data,
          submitted: true
        });

        toast.success("Compliment sent successfully!", {
          onClose: () => navigate('/') // Redirect to the home page when the toast is closed
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
        toast.error("Error sending the compliment."); // Display an error toast
      });
  }

   // Define a function to handle the onChange for each field
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompliment({ ...compliment, [name]: value });
  };
  
    return (
      <div className="submit-form">
       <ToastContainer />
         <div>
            <h1>Send a Compliment</h1>
            <h4>Personal Details</h4> 
            <Row style={sectionStyle}>
            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                value={compliment.first_name}
                onChange={handleInputChange}
                name="first_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                value={compliment.last_name}
                onChange={handleInputChange}
                name="last_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="other_names">Other Names</label>
              <input
                type="text"
                className="form-control"
                id="other_names"
                value={compliment.other_names}
                onChange={handleInputChange}
                name="other_names"
              />
            </div>
              </Col>
            </Row>
      

            <Row>
              <Col>
             <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                id="gender"
                value={compliment.gender}
                onChange={handleInputChange}
                name="gender"
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
              </select>
            </div> 
     

              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                value={compliment.phone_number}
                onChange={handleInputChange}
                name="phone_number"
              />
            </div>
              </Col>
            </Row>
            


            <div className="form-group">
              <label htmlFor="street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="street_address"
                value={compliment.street_address}
                onChange={handleInputChange}
                name="street_address"
              />
            </div>



              <Row>
                <Col>
                <div className="form-group">
              <label htmlFor="village">Village</label>
              <input
                type="text"
                className="form-control"
                id="village"
                value={compliment.village}
                onChange={handleInputChange}
                name="village"
              />
            </div>
                </Col>
                <Col>
              <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                id="region"
                value={compliment.region}
                onChange={handleInputChange}
                name="region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
                </Col>
              </Row>
            </Row>



            <h4>Officer Details</h4>
            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="police_name">
                Name/s of Police Officer/s Involved
              </label>
              <textarea
                type="text"
                className="form-control"
                id="police_name"
                value={compliment.police_name}
                onChange={handleInputChange}
                name="police_name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="rank_or_desc">Rank Description</label>
              <textarea
                type="text"
                className="form-control"
                id="rank_or_desc"
                value={compliment.rank_or_desc}
                onChange={handleInputChange}
                name="rank_or_desc"
              />
            </div>

            <div className="form-group">
              <label htmlFor="other_id">Other Means of Identification</label>
              <textarea
                type="text"
                className="form-control"
                id="other_id"
                value={compliment.other_id}
                onChange={handleInputChange}
                name="other_id"
              />
            </div>

            <div className="form-group">
              <label htmlFor="vehicle_no">Vehicle Number</label>
              <textarea
                type="text"
                className="form-control"
                id="vehicle_no"
                value={compliment.vehicle_no}
                onChange={handleInputChange}
                name="vehicle_no"
              />
            </div>

            <div className="form-group">
              <label htmlFor="attached_station">
                Details of the unit where the police officer work or is
                attached.
              </label>
              <textarea
                type="text"
                className="form-control"
                id="attached_station"
                value={compliment.attached_station}
                onChange={handleInputChange}
                name="attached_station"
              />
            </div>

            <div className="form-group">
              <label htmlFor="remarks">
              Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                value={compliment.remarks}
                onChange={handleInputChange}
                name="remarks"
              />
            </div>
            </Row>

            <button onClick={saveCompliment} className="btn btn-success">
              Submit
            </button>
          </div>
      </div>
    );
  
}
export default AddCompliment;