import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
//import Container from "react-bootstrap/Container";
//import { Col, Row, Card, Button } from "react-bootstrap";

function PrivacyPolicy() {

    return <iframe src="/test.html" 
    title="Privacy Policy"
    frameBorder="0"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none'
      }}
    ></iframe>;
}
export default PrivacyPolicy;
