import React from "react";
//import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
//import axios from "axios";

// import ViewSuggestion from "./view-suggestion.component";


const SuggestionTableRow = props => {
  const {
    // id,
    name,
    email,
    suggestion_box
  } = props.obj;

  return (
    <tr>
      <td>
        {name}
      </td>
      <td>
        {email}
      </td> 
      <td>
        {suggestion_box}
      </td>

      {/* <td>
        <Link
          className="edit-link"
          to={"/view-suggestion/" + id}
          element={<ViewSuggestion />}
        >
          View
        </Link>
      </td> */}
    </tr>
  );
};

export default SuggestionTableRow;
