import React from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { isAuthenticated } from './components/Utils/auth';


import Dashboard from "./components/dashboard.component";
import AddReport from "./components/Report/add-report.component";
import AddUser from "./components/User/add-user.component";
import Welcome from "./components/home-page.component";
import PrivacyPolicy from "./components/privacy-policy.component";
import ReportList from "./components/Report/report-list.component";
import EditReport from "./components/Report/edit-report.component";
import AddComplaint from "./components/Complaint/add-complaint.component";
import ComplaintList from "./components/Complaint/complaint-list.component";
import ViewComplaint from "./components/Complaint/view-complaint.component";
import UserList from "./components/User/user-list.component";
import EditUser from "./components/User/edit-user.component";
import StationList from "./components/Station/station-list.component";
import EditStation from "./components/Station/edit-station.component";
import AddStation from "./components/Station/add-station.component";
import Login from "./components/Login/login.component";
import FollowUp from "./components/Follow_up/follow-up.component";
import AddSuggestion from "./components/Suggestion/add-suggestion.component";
import SuggestionList from "./components/Suggestion/suggestion-list.component";
import AddCompliment from "./components/Compliment/add-compliment.component";
import ComplimentList from "./components/Compliment/compliment-list.component";
import ResetPassword from "./components/User/password-reset.component";

import { useNavigate } from 'react-router-dom';
import { useAuth } from './components/Context/AuthContext'; // Import useAuth


function App() {

  const { isLoggedIn, userRole,logout } = useAuth(); // Get isLoggedIn and logout from the AuthContext
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
 
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <Container>
            <a href="/" className="navbar-brand">
            <img src={process.env.PUBLIC_URL +'/secure_eye.png'} alt="Secure Eye Logo" width="30" height="30" className="d-inline-block align-top"/>&nbsp;&nbsp;
            <b>Secure Eye</b>   
            </a>
            <div className="navbar-nav mr-auto">
            {isLoggedIn ? (
                <>
                  {/* Elements to show when the user is logged in */}

                  <li className="nav-item">
                    <Link
                      to={"/dashboard"}
                      element={<Dashboard />}
                      className="nav-link"
                    >
                      Dashboard
                    </Link>
                  </li>

              

                <li className="nav-item">
                <Button  onClick={handleLogout}>Logout</Button>

                </li>
                
                </>
              ) : (
                <>
                  {/* Elements to show when the user is logged out */}
                  <li className="nav-item">
                    <Link to={"/"} element={<Welcome />} className="nav-link">
                      Welcome
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/privacy_policy"} element={<PrivacyPolicy/>} className="nav-link">
                      Privacy Policy
                    </Link>
                  </li>
              {/* <li className="nav-item">
                <Link
                  to={"/register"}
                  element={<AddUser />}
                  className="nav-link"
                >
                  Register
                </Link>
              </li> */}

              <li className="nav-item">
                <Link
                  to={"/login"}
                  element={<Login/>}
                  className="nav-link"
                >
                  Login
                </Link>
              </li> 
                </>
              )}
            
      
            </div>
          </Container>
        </nav>

        <Container>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/add" element={<AddReport />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            {/* <Route path="/register" element={<AddUser />} /> */}
            <Route
              path="/register"
              element={isAuthenticated() && userRole === 'Admin'? <AddUser /> : <Navigate to="/login" replace />}
            />
            <Route path="/add-complaint" element={<AddComplaint />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route
              path="/dashboard"
              element={isAuthenticated() ? <Dashboard /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/report_list" element={<ReportList />} /> */}
            <Route
              path="/report_list"
              element={isAuthenticated() ? <ReportList /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/complaint_list" element={<ComplaintList />} /> */}
            <Route
              path="/complaint_list"
              element={isAuthenticated() ? <ComplaintList /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/view-complaint/:id" element={<ViewComplaint />} /> */}
            <Route
              path="/view-complaint/:id"
              element={isAuthenticated() ? <ViewComplaint /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/edit-report/:id" element={<EditReport />} /> */}
            <Route
              path="/edit-report/:id"
              element={isAuthenticated() ? <EditReport /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/user_list" element={<UserList />} /> */}
            <Route
              path="/user_list"
              element={isAuthenticated() && userRole === 'Admin' ? <UserList /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/edit-user/:id" element={<EditUser />} /> */}
            <Route
              path="/edit-user/:id"
              element={isAuthenticated() && userRole === 'Admin'? <EditUser  /> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/station_list" element={<StationList />} /> */}
            <Route
              path="/station_list"
              element={isAuthenticated() && userRole === 'Admin' ? <StationList/> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/edit-station/:id" element={<EditStation />} /> */}
            <Route
              path="/edit-station/:id"
              element={isAuthenticated() && userRole === 'Admin'? <EditStation/> : <Navigate to="/login" replace />}
            />
            {/* <Route path="/add-station" element={<AddStation />} /> */}
            <Route
              path="/add-station"
              element={isAuthenticated() && userRole === 'Admin' ? <AddStation/> : <Navigate to="/login" replace />}
            />
             <Route
              path="/password-reset/:id"
              element={isAuthenticated() && userRole === 'Admin' ? <ResetPassword/> : <Navigate to="/login" replace />}
            />

            <Route
              path="/suggestion-list"
              element={isAuthenticated() ? <SuggestionList /> : <Navigate to="/login" replace />}
            /> 

          <Route
              path="/compliment-list"
              element={isAuthenticated()  ? <ComplimentList /> : <Navigate to="/login" replace />}
            /> 

            <Route path="/add-suggestion" element={<AddSuggestion />} />

            <Route path="/add-compliment" element={<AddCompliment/>} />

            <Route path="/follow-up" element={<FollowUp />} />
            <Route path="/station" element={<StationList />} />
            <Route path="/login" element={<Login />} />

          </Routes>
        </Container>
      </header>

      <main className="p-2" />

      <footer
        className="border p-2 mt-auto text-white text-center"
        style={{ backgroundColor: "black" }}
      >
        <Container>Copyright © Ministry of Home Affairs 2023</Container>
      </footer>
    </div>
  );
}

export default App;
