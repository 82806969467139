import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import axiosInstance from '../../services/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddStation = (props) => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const [station, setStation] = useState({
      id: null,
      station_name: "",
      location: "",
      phone_no: "",
      div_id: "",
      reg_id: "",

      submitted: false
    });
  


  const saveStation = () => {

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    // StationsDataService.create(data)
    axiosInstance.post('/api/stations', station, config)
      .then(response => {
        setStation({
          ...response.data,
          submitted: true
        });

        toast.success("Station Added successfully!", {
          onClose: () => navigate('/') // Redirect to the home page when the toast is closed
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
        toast.error("Error adding the station."); // Display an error toast
      });
  }

     // Define a function to handle the onChange for each field
     const handleInputChange = (event) => {
      const { name, value } = event.target;
      setStation({ ...station, [name]: value });
    };
  
    return (
      <div className="submit-form">
      <ToastContainer />
           <div>
              <h1>Add New Station</h1>
              <Row>
                <Col>
                  <div className="form-group">
                    <label htmlFor="station">Station Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="station"
                      value={station.station_name}
                      onChange={handleInputChange}
                      name="station"
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      value={station.location}
                      onChange={handleInputChange}
                      name="location"
                    />
                  </div>
                </Col>
              </Row>
            

              <Row>
                <Col>
                <div className="form-group">
                    <label htmlFor="phone_no">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone_no"
                      value={station.phone_no}
                      onChange={handleInputChange}
                      name="phone_no"
                    />
                  </div>
                </Col>
                <Col>
             

                  <div className="form-group">
              <label htmlFor="div_id">Division</label>
              <select
                className="form-control"
                id="div_id"
                value={station.div_id}
                onChange={handleInputChange}
                name="div_id"
              >
                <option>Please select an option</option>
                <option value={1}>Division 1</option>
                <option value={2}>Division 2</option>
                <option value={3}>Division 3</option>
                <option value={'4a'}>Division 4a</option>
                <option value={'4b'}>Division 4b</option>
                <option value={'4c'}>Division 4c</option>
                <option value={5}>Division 5</option>
                <option value={6}>Division 6</option>
                <option value={7}>Division 7</option>
                <option value={8}>Division 8</option>
                <option value={9}>Division 9</option>
                <option value={10}>Division 10</option>
              </select>
            </div> 

                </Col>

                <Col>
           

                  <div className="form-group">
                  <label htmlFor="reg_id">Region</label>
                  <select
                    className="form-control"
                    id="reg_id"
                    value={station.reg_id}
                    onChange={handleInputChange}
                    name="reg_id"
                  >
                    <option value={0}>Choose a Region</option>
                    <option value={1}>Region 1 Barima-Waini</option>
                    <option value={2}>Region 2 Pomeroon-Supenaam</option>
                    <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                    <option value={4}>Region 4 Demerara-Mahaica</option>
                    <option value={5}>Region 5 Mahaica-Berbice</option>
                    <option value={6}>Region 6 East Berbice-Corentyne</option>
                    <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                    <option value={8}>Region 8 Potaro-Siparuni</option>
                    <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                    <option value={10}>Region 10 Upper Demerara-Berbice</option>
                  </select>
                </div>
                </Col>
              </Row>

              <button onClick={saveStation} className="btn btn-success">
                Submit
              </button>
            </div>
      </div>
    );
  
}
export default AddStation;