import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import ComplaintTableRow from "./ComplaintTableRow";
import ReactPaginate from 'react-paginate';
import './../paginate.css';
import moment from 'moment';

import { useAuth } from '../Context/AuthContext'; // Import useAuth

import axiosInstance from '../../services/axiosInstance';

const ComplaintList = () => {
  const [complaints, setComplaints] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(1); // the number of page numbers to show at a time
  const {userRole, userRegion} = useAuth();

  useEffect(() => {

    const fetchComplaints = async () => {
      try {
        let response;
        if(userRole==="Admin" || userRole==="Data Entry"){
          response = await axiosInstance.get("/api/complaints");
        } else {
          response = await axiosInstance.get(`/api/complaints/complaintRegion/${userRegion}`);
        }
  
        // Sort complaints by date, oldest first.
        const sortedComplaints = response.data.sort((a, b) => {
               // Use moment to parse the dates
               const dateA = moment(a.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Adjust the format string to match your timestamp format
               const dateB = moment(b.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Adjust the format string to match your timestamp format
       
               // Compare the moment objects
               return dateB.valueOf() - dateA.valueOf(); // this sorts newest to oldest, considering both date and time
        });
  
        setComplaints(sortedComplaints);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplaints();
  
  }, [userRole, userRegion]);

  const filteredComplaints = complaints.filter(complaint => {
    const searchFields = [
      complaint.first_name,
      complaint.last_name,
      complaint.incident,
      complaint.inci_region?.toString(),  // Convert to string to perform the includes check
      complaint.inci_village,
      complaint.inci_division,
      complaint.gender
    ];
  
    // Normalize each field before checking if the search term is included
    return searchFields.some(field =>
      field
        ? field.toLowerCase().includes(searchTerm.toLowerCase())
        : false
    );
  });
  

  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredComplaints.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <ComplaintTableRow obj={res} key={i} />;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredComplaints.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  } 

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }


  return (
    <Container>
      <h1>Complaint List</h1>
      <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>User Cat</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Other Names</th>
            <th>Gender</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Street Address</th>
            <th>Village</th>
            <th>Region</th>
            <th>Victim Name</th>
            <th>Victim Age</th>
            <th>Victim Gender</th>
            <th>Incident</th>
            <th>Incident Date</th>
            <th>Incident Time</th>
            <th>Incident Location</th>
            <th>Incident Address</th>
            <th>Village</th>
            <th>Division</th>
            <th>Region</th>
            <th>Injuries or Damages</th>
            <th>Injuries/Damages Description</th>
            <th>Witnesses Present</th>
            <th>Witness Description</th>
            <th>Was Incident Reported?</th>
            <th>Given Reference</th>
            <th>Name/s of Police</th>
            <th>Rank Description</th>
            <th>Other means of Id</th>
            <th>Attached Station</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {DataTable()}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredComplaints.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />

    </Container>
  );
};

export default ComplaintList;
