// EditStation Component for update station data

// Import Modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

// EditStation Component
const EditStation = () => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const[submitted, setSubmitted]=useState(false);
  const [divisions, setDivisions] = useState([]);
  const [formValues, setFormValues] = useState({
    station_name: "",
    location: "",
    phone_no: "",
    div_id: "",
    reg_id: ""
  });
  

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDivisionListnChange = async (event) => {
    const { name, value } = event.target;
    

    if (name === "reg_id") {
      setFormValues({ ...formValues, [name]: value, division: ""  });

      const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${value}`);
      setDivisions(divisionsResponse.data);
      console.log(divisionsResponse.data); 
    }else {
    setFormValues({ ...formValues, [name]: value });
  }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitted(true)
    toast.success("Station Updated successfully!", {
      onClose: () => navigate('/station_list') // Redirect to the home page when the toast is closed
    });

    try {
      const response = await axiosInstance.put(
        `/api/stations/${id}`,
        formValues
      );
      // do something with the response, such as displaying a success message
      console.log(response.data);
    } catch (error) {
      // handle the error, such as displaying an error message
      console.log(error);
    }
  };

  // Load data from server and reinitialize station form
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/stations/${id}`
        );
        setFormValues(response.data);

        if (response.data.reg_id) {
          const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${response.data.inci_region}`);
          setDivisions(divisionsResponse.data);
          console.log(divisionsResponse.data);    
      }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Return report form
  return (
    <Container>
    <ToastContainer />
      <form className="submit-form" onSubmit={handleSubmit}>
        <h1>Update Station</h1>

        <Row>
          <Col>
            <div className="form-group">
              <label>Station Name:</label>
              <input
                className="form-control"
                type="text"
                name="station"
                value={formValues.station_name || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>Location:</label>
              <input
                className="form-control"
                type="text"
                name="location"
                value={formValues.location || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>
        </Row>

        <Row>
        <Col>
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                className="form-control"
                type="text"
                name="phone_no"
                value={formValues.phone_no || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label>Division:</label>

              <select
                className="form-control"
                id="div_id"
                value={formValues.div_id || ""}
                onChange={handleChange}
                name="div_id"
              >
                 <option value="">Select a division</option>
                    {divisions.map((division, index) => (
                      <option key={index} value={division.div_id}>
                        {division.div_name}
                      </option>
                    ))}
              </select>         

              <br />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label>Region:</label>
                  <select
                    className="form-control"
                    id="reg_id"
                    value={formValues.reg_id || ""}
                    onChange={handleDivisionListnChange}
                    name="reg_id"
                  >
                    <option value={0}>Choose a Region</option>
                    <option value={1}>Region 1 Barima-Waini</option>
                    <option value={2}>Region 2 Pomeroon-Supenaam</option>
                    <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                    <option value={4}>Region 4 Demerara-Mahaica</option>
                    <option value={5}>Region 5 Mahaica-Berbice</option>
                    <option value={6}>Region 6 East Berbice-Corentyne</option>
                    <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                    <option value={8}>Region 8 Potaro-Siparuni</option>
                    <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                    <option value={10}>Region 10 Upper Demerara-Berbice</option>
                  </select>

              <br />
            </div>
          </Col>
        </Row>

        <button type="submit" className="btn btn-success">Submit</button>
      </form>
    </Container>
  );
};

// Export EditStation Component
export default EditStation;
