import React, { useState } from 'react';
import { Col, Row, Form, Button, Alert } from "react-bootstrap";
//import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import axiosInstance from '../../services/axiosInstance';

const FollowUp = () => {
  const [refNo, setRefNo] = useState('');
  const [data, setData] = useState(null); // new state variable to hold the fetched data
  const [error, setError] = useState(null); // new state variable to hold any errors that occur

 
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent the default form submission behavior
  
    try {
      const response = await axiosInstance.get(`/api/reports/reportRef/${refNo}`);
      setData(response.data); // update the state with the fetched data
      setError(null); // clear any previous error messages
    } catch (error) {
      setData(null); // clear any previously fetched data
      setError(error.message); // update the state with the error message
    }
  };
  

  return (
    <><Container fluid>
    
            <Row>
                <Col></Col>
                <Col xs={5}>
                <h1>Enter the number you were given</h1><br></br>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Search</Form.Label>
              <Form.Control type="text" placeholder="Enter Reference Number" value={refNo} onChange={(e) => setRefNo(e.target.value)} />
            </Form.Group>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>} {/* display any error messages */}
          {data && (
            <div>
              <h2>Results</h2>
              <table>
              <tr>
              <th>Reference Number</th>
              <th>Report Category</th>
              <th>Report Description</th>
              <th>Report Status</th>
            </tr>
            <tr>
              <td>{data.ref_no}</td> {/* example: display the fetched data in the UI */}
              <td>{data.report_category}</td>
              <td>{data.file_1_description}</td>
              <td>{data.report_status}</td>
              </tr>
            </table>
            </div>
          )} {/* conditionally render the results if data has been fetched */}
                </Col>
                <Col></Col>
            </Row>
    </Container>
</>
  );
};

export default FollowUp;
