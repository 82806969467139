import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext'; // Import useAuth

import axiosInstance from '../../services/axiosInstance';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); 
  const navigate = useNavigate();

  const { login } = useAuth(); // Get the login function from the AuthContext
  const centerImageStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 70,
    paddingBottom: 30,
    // height: '100vh', // Optional: Set a height to center vertically within the viewport
  };
  const loginHeader={
    textAlign: 'center',
  };
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    try {
    
      const res = await axiosInstance.post('/api/users/login', { email, password });
      //const { token, email:userEmail, first_name } = res.data;
      login(res.data.token, email);
      console.log( email)
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid username or password'); // set the error message
      console.log(err);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col></Col>
          <Col xs={5}>
          <div style={centerImageStyles}>
          <img src={process.env.PUBLIC_URL +'/secure_eye.png'} alt="Secure Eye Logo" width="150" height="120" className="d-inline-block align-top" />
          </div>

          {error && <Alert variant="danger">{error}</Alert>}  {/* Show error message if there is an error */}


          <div >
          <h1 style={loginHeader}>Log In</h1>
          </div>
            

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox"></Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
