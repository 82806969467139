// EditUser Component for update user data

// Import Modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import axiosInstance from '../../services/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ResetPassword Component
const ResetPassword = () => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const[submitted, setSubmitted]=useState(false);
  const [formValues, setFormValues] = useState({
    new_password: ""
  });
    
  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const handleSubmit = async event => {
    event.preventDefault();

    

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.put(
        `/api/users/passwordReset/${id}`,
        formValues
      );
      // do something with the response, such as displaying a success message
      setSubmitted(true)

      toast.success("User password changed successfully!", {
        onClose: () => navigate('/user_list') // Redirect to the home page when the toast is closed
      });
     // console.log(response.data);
    } catch (error) {
      // handle the error, such as displaying an error message
      console.log(error);
      toast.error("Error changing the user password."); // Display an error toast
    }
  };


  // Return report form
  return (
  
    <Container>
  <ToastContainer />
      <form className="submit-form" onSubmit={handleSubmit}>
        <h1>Reset User Password</h1>

        <div className="form-group">
          <label>New Password:</label>
          <input
            className="form-control"
            type="password"
            name="new_password"
            value={formValues.new_password || ""}
            onChange={handleChange}
          />          
          <br />
        </div>

        <button type="submit" className="btn btn-success">Submit</button>
      </form>
    </Container>
  );
};

// Export EditUser Component
export default ResetPassword;
