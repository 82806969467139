import React from "react";
//import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
//import axios from "axios";

// import ViewComplaint from "./view-complaint.component";


const ComplimentTableRow = props => {
  const {
    // id,
    first_name,
    last_name,
    other_names,
    gender,
    phone_number,
    email,
    street_address,
    village,
    region,
    police_name,
    rank_or_desc,
    other_id,
    vehicle_no,
    attached_station,
    remarks
  } = props.obj;

  return (
    <tr>
      <td>
        {first_name}
      </td>
      <td>
        {last_name}
      </td>
      <td>
        {other_names}
      </td>
      <td>
        {gender}
      </td>
      <td>
        {phone_number}
      </td>
      <td>
        {email}
      </td>
      <td>
        {street_address}
      </td>
      <td>
        {village}
      </td>
      <td>
        {region}
      </td>
      <td>
        {police_name}
      </td>
      <td>
        {rank_or_desc}
      </td>
      <td>
        {other_id}
      </td>
      <td>
        {vehicle_no}
      </td>
      <td>
        {attached_station}
      </td>
      <td>
        {remarks}
      </td>

      {/* <td>
        <Link
          className="edit-link"
          to={"/view-complaint/" + id}
          element={<ViewComplaint />}
        >
          View
        </Link>
      </td> */}
    </tr>
  );
};

export default ComplimentTableRow;
