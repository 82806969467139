import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Col, Row, Card } from "react-bootstrap";


import ReportList from "./Report/report-list.component";
import ComplaintList from "./Complaint/complaint-list.component";
import UserList from "./User/user-list.component";
import StationList from "./Station/station-list.component";
import axiosInstance from '../services/axiosInstance';

import { useAuth } from './Context/AuthContext';
import SuggestionList from "./Suggestion/suggestion-list.component";
import ComplimentList from "./Compliment/compliment-list.component";



function Dashboard() {
  const [count, setCount] = useState(null);
  const [complaint_count, setComplaintCount] = useState(null);
  const [user_count, setUserCount] = useState(null);
  const [station_count, setStationCount] = useState(null); 
  const [compliment_count, setComplimentCount] = useState(null); 
  const [suggestion_count, setSuggestionCount] = useState(null); 
  const {userName, userRole} = useAuth();


  useEffect(() => {
    axiosInstance.get('/api/reports/reportCount')
      .then(response => setCount(response.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    axiosInstance.get('/api/complaints/complaintCount')
      .then(response => setComplaintCount(response.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    axiosInstance.get('/api/users/userCount')
      .then(response => setUserCount(response.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    axiosInstance.get('/api/stations/stationCount')
      .then(response => setStationCount(response.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    axiosInstance.get('/api/compliment/complimentCount')
      .then(response => setComplimentCount(response.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    axiosInstance.get('/api/suggestions/suggestionCount')
      .then(response => setSuggestionCount(response.data.count))
      .catch(error => console.error(error));
  }, []);


  return (
    <Container fluid>

      <Col></Col>
      <Col xs={12}>
      <Row>
      <h1 className="d-flex justify-content-center mb-3">Dashboard</h1>
      <p className="d-flex justify-content-center mb-3">Welcome, {userName ? userName : 'loading...'}!</p>
      <p className="d-flex justify-content-center mb-3"><b>User Role:</b> {userRole ? userRole : 'loading...'}</p>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mb-5">
          <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="primary"
            text="white"
          >
            <Card.Header>{count !== null ? <p>Total Reports: {count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Reports</Card.Title>
              <Card.Text>
                <Link
                  to={"/report_list"}
                  element={<ReportList />}
                  className="nav-link"
                >
                  View all Reports
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
        <Col className="d-flex justify-content-center mb-5">
          <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="danger"
            text="white"
          >
            <Card.Header>{complaint_count !== null ? <p>Total Complaints: {complaint_count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Complaints</Card.Title>
              <Card.Text>
                <Link
                  to={"/complaint_list"}
                  element={<ComplaintList />}
                  className="nav-link"
                >
                  View all Complaints
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center mb-5">
          <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="primary"
            text="white"
          >
            <Card.Header>{compliment_count !== null ? <p>Total Compliments: {compliment_count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Compliments</Card.Title>
              <Card.Text>
                <Link
                  to={"/compliment-list"}
                  element={<ComplimentList />}
                  className="nav-link"
                >
                  View all Compliments
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
        <Col className="d-flex justify-content-center mb-5">
          <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="danger"
            text="white"
          >
            <Card.Header>{suggestion_count !== null ? <p>Total Suggestions: {suggestion_count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Suggestions</Card.Title>
              <Card.Text>
                <Link
                  to={"/suggestion-list"}
                  element={<SuggestionList />}
                  className="nav-link"
                >
                  View all Suggestions
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
      </Row>
      {userRole === 'Admin' &&  
      <Row>
    
        <Col className="d-flex justify-content-center mb-5">
          <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="warning"
            text="white"
          >
            <Card.Header>{user_count !== null ? <p>Total Users: {user_count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Users</Card.Title>
              <Card.Text>
                <Link
                  to={"/user_list"}
                  element={<UserList />}
                  className="nav-link"
                >
                  View all Users
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
        

        <Col className="d-flex justify-content-center mb-5">
        <Card
            border="dark"
            style={{ width: "30rem" }}
            bg="success"
            text="white"
          >
            <Card.Header>{station_count !== null ? <p>Total Stations: {station_count}</p> : <p>Loading...</p>}</Card.Header>
            <Card.Body>
              <Card.Title>Stations</Card.Title>
              <Card.Text>
                <Link
                  to={"/station_list"}
                  element={<StationList />}
                  className="nav-link"
                >
                  View all Stations
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
        </Col>
       
      </Row>
    }
      <Row>
        <Col>
         
          <br />
        </Col>
        <Col>
          <br />
        </Col>
      </Row>
      </Col>
      <Col></Col>

    </Container>
  );
}
export default Dashboard;
