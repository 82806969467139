import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useAuth } from '../Context/AuthContext'; // Import useAuth
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

const AddReport =()=> {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  const {userId} = useAuth();
  const [report, setReport] = useState({
      id: null,
      user_cat: "",
      first_name: "",
      last_name: "",
      other_names: "",
      gender: "",
      phone_number: "",
      email: "",
      street_address: "",
      village: "",
      region: 1,
      report_category: "",
      file_1: "",
      file_1_description: "",
      from_date: new Date().toISOString().split('T')[0],
      from_time: "",
      to_date: new Date().toISOString().split('T')[0],
      to_time: "",
      inci_location_desc: "",
      inci_street_address: "",
      inci_village: "",
      inci_region: 1,
      sus_id_known: "",
      sus_first_name: "",
      sus_last_name: "",
      sus_aliases: "",
      sus_gender:"",
      sus_ethnicity:"",
      sus_dist_marks:"",
      sus_phone_number: "",
      sus_address: "",
      sus_vehicle_known: "",
      sus_license_plate_no: "",
      sus_vehicle_desc: "",
      wit_id_known: "",
      wit_full_name: "",
      wit_gender: "",
      wit_address: "",
      wit_phone_no: "",

      submitted: false
    });

    let fromDateFormatted = new Date(report.from_date).toISOString().split('T')[0];
    let toDateFormatted = new Date(report.to_date).toISOString().split('T')[0];

    const sectionStyle = {
      marginBottom: 40,
     };
  
    const onChangeData = (e) => {
      const { name, value } = e.target;
      if (e.target.type === 'file') {
        // If it's a file input, update the state with the file
        setReport(prevState => ({
          ...prevState,
          [name]: e.target.files[0],
        }));
      } else {
        setReport(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    };

  const saveReport = (e) => {

    e.preventDefault();

    const formData = new FormData();
    formData.append("userId", userId);

    for (let key in report) {
      if (key !== 'submitted') { // Exclude the submitted field when sending data
        formData.append(key, report[key]);
      }
    }


      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }

      axiosInstance.post('/api/reports', formData, config)
      .then(response => {
       
        setReport({
          ...report,
          submitted: true, // Set submitted to true
        });
        toast.success("Report sent successfully!", {
          onClose: () => navigate('/') // Redirect to the home page when the toast is closed
        });
            // Save activity after a successful report submission
        saveUserActivity(response.data);
      })
      .catch(e => {
          console.log(e);
      });


}

const saveUserActivity = (reportData) => {
  const activity = {
      user_id: userId,
      activity_type: "Add Report",
      activity_details: JSON.stringify(reportData),  // Attach the entire report response data as activity details
      activity_time: new Date().toISOString(),
  };

  axiosInstance.post('/api/user_activities', activity)
      .then(response => {
          console.log("User activity saved successfully.", response.data);
      })
      .catch(e => {
          console.log("Error saving user activity.", e);
      });
}

 

    return (
      <div className="submit-form">
      <ToastContainer />
      <Container fluid>
            <h1>Add a New Report</h1>
          
          <Row style={sectionStyle}>

          </Row>

          <Row style={sectionStyle}>
          <div className="form-group">
              <label htmlFor="user_cat">Do you want to be Known?</label>
              <select
                className="form-control"
                id="user_cat"
                value={report.user_cat}
                onChange={onChangeData}
                name="user_cat"
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

            {report.user_cat === "Yes" && (<>

              <h4>Personal Details</h4> 
            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                value={report.first_name}
                onChange={onChangeData}
                name="first_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                value={report.last_name}
                onChange={onChangeData}
                name="last_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="other_names">Other Names</label>
              <input
                type="text"
                className="form-control"
                id="other_names"
                value={report.other_names}
                onChange={onChangeData}
                name="other_names"
              />
            </div>
              </Col>
            </Row>
      

            <Row>
              <Col>
             <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                id="gender"
                value={report.gender}
                onChange={onChangeData}
                name="gender"
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div> 
     

              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                value={report.phone_number}
                onChange={onChangeData}
                name="phone_number"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={report.email}
                onChange={onChangeData}
                name="email"
              />
            </div>
              </Col>
            </Row>
            


            <div className="form-group">
              <label htmlFor="street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="street_address"
                value={report.street_address}
                onChange={onChangeData}
                name="street_address"
              />
            </div>



              <Row>
                <Col>
                <div className="form-group">
              <label htmlFor="village">Village</label>
              <input
                type="text"
                className="form-control"
                id="village"
                value={report.village}
                onChange={onChangeData}
                name="village"
              />
            </div>
                </Col>
                <Col>
              <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                id="region"
                value={report.region}
                onChange={onChangeData}
                name="region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
                </Col>
              </Row>


           

            </>)}
          </Row>


            <h4>What Happened?</h4> 

            <Row style={sectionStyle}>
                <div className="form-group">
                  <label htmlFor="report_category">Report Category</label>
                  <select
                    className="form-control"
                    id="report_category"
                    value={report.report_category}
                    onChange={onChangeData}
                    name="report_category"
                  >
                    <option>Please select an option</option>
                    <option>General Crime</option>
                    <option>Domestic Violence</option>
                    <option>Noise Nuisance</option>
                    <option>Death</option>
                    <option>Sexual Assault</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="file_1">
                    Upload photo or video evidence of the incident
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="file_1"
                    onChange={onChangeData}
                    name="file_1"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="file_1_description">Describe the incident</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="file_1_description"
                    value={report.file_1_description}
                    onChange={onChangeData}
                    name="file_1_description"
                  />
                </div>
            </Row>


            <h4>When Did It Happen?</h4>

            <Row style={sectionStyle}>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="from_date">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="from_date"
                    value={fromDateFormatted}
                    onChange={onChangeData}
                    name="from_date"
                  />
                </div>
              </Col>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="from_time">From Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="from_time"
                    value={report.from_time}
                    onChange={onChangeData}
                    name="from_time"
                  />
                </div>
              </div>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="to_date">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="to_date"
                    value={toDateFormatted}
                    onChange={onChangeData}
                    name="to_date"
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="to_time">To Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="to_time"
                    value={report.to_time}
                    onChange={onChangeData}
                    name="to_time"
                  />
                </div>
              </Col>
            </Row>
            </Row>



            <h4>Where Did It Happen?</h4>

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="inci_location">
                Describe the location where the
                incident happened
              </label>
              <textarea
                className="form-control"
                id="inci_location_desc"
                value={report.inci_location_desc}
                onChange={onChangeData}
                name="inci_location_desc"
              >
              </textarea>
            </div>

            <div className="form-group">
              <label htmlFor="inci_street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="inci_street_address"
                value={report.inci_street_address}
                onChange={onChangeData}
                name="inci_street_address"
              />
            </div>
              <Row>
                <Col>
                <div className="form-group">
              <label htmlFor="inci_village">Village</label>
              <input
                type="text"
                className="form-control"
                id="inci_village"
                value={report.inci_village}
                onChange={onChangeData}
                name="inci_village"
              />
            </div>
                </Col>
                <Col>
                {/* <div className="form-group">
                  <label htmlFor="regions">Region</label>
                  <Dropdown className="form-control" options={this.regions} onChange={this._onSelect} placeholder='Please select an option' />
                </div> */}
                <div className="form-group">
              <label htmlFor="inci_region">Region</label>
              <select
                className="form-control"
                id="inci_region"
                value={report.inci_region}
                onChange={onChangeData}
                name="inci_region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>

                </Col>
              </Row>
            </Row>




            <h4>Who Was Involved?</h4>

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="sus_id_known">Do you know the Suspect ?</label>
              <select
                className="form-control"
                id="sus_id_known"
                value={report.sus_id_known}
                onChange={onChangeData}
                name="sus_id_known"
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

            {report.sus_id_known === "Yes" && (<>

            <h5>If yes, please provide the following information:</h5>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="sus_first_name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_first_name"
                    value={report.sus_first_name}
                    onChange={onChangeData}
                    name="sus_first_name"
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="sus_last_name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_last_name"
                    value={report.sus_last_name}
                    onChange={onChangeData}
                    name="sus_last_name"
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="sus_aliases">Aliases</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_aliases"
                    value={report.sus_aliases}
                    onChange={onChangeData}
                    name="sus_aliases"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="sus_gender">Gender</label>
              <select
                className="form-control"
                id="sus_gender"
                value={report.sus_gender}
                onChange={onChangeData}
                name="sus_gender"
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div> 
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="sus_ethnicity">Ethnicity</label>
              <select
                className="form-control"
                id="sus_ethnicity"
                value={report.sus_ethnicity}
                onChange={onChangeData}
                name="sus_ethnicity"
              >
                <option>Please select an option</option>
                <option>Indian</option>
                <option>African</option>
                <option>Amerindian</option>
                <option>European</option>
                <option>Chinese</option>
                <option>Mixed</option>
                <option>Other</option>
              </select>
            </div> 
              </Col>
            </Row>

            <div className="form-group">
              <label htmlFor="sus_dist_marks">Distinct Marks '(' Scars, Birthmarks, Moles, tattoos etc ')'</label>
              <textarea
                type="text"
                className="form-control"
                id="sus_dist_marks"
                value={report.sus_dist_marks}
                onChange={onChangeData}
                name="sus_dist_marks"
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="sus_phone_number"
                value={report.sus_phone_number}
                onChange={onChangeData}
                name="sus_phone_number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_address">Address</label>
              <input
                type="text"
                className="form-control"
                id="sus_address"
                value={report.sus_address}
                onChange={onChangeData}
                name="sus_address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_vehicle_known">
                Do you know if the person(s) who did this used a vehicle?
              </label>
              <select
                className="form-control"
                id="sus_vehicle_known"
                value={report.sus_vehicle_known}
                onChange={onChangeData}
                name="sus_vehicle_known"
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

            {report.sus_vehicle_known === "Yes" && (<>


            <h5>If yes, please provide the following information:</h5>

            <div className="form-group">
              <label htmlFor="sus_license_plate_no">
                License Plate Number
              </label>
              <input
                type="text"
                className="form-control"
                id="sus_license_plate_no"
                value={report.sus_license_plate_no}
                onChange={onChangeData}
                name="sus_license_plate_no"
              />
            </div>
            <div className="form-group">
              <label htmlFor="sus_license_plate_no">
                Vehicle Description
              </label>
              <textarea
                type="text"
                className="form-control"
                id="sus_vehicle_desc"
                value={report.sus_vehicle_desc}
                onChange={onChangeData}
                name="sus_vehicle_desc"
              />
            </div>
            </>)}

            </>)}

            <div className="form-group">
              <label htmlFor="wit_id_known">
                Was there a witness?
              </label>
              <select
                className="form-control"
                id="wit_id_known"
                value={report.wit_id_known}
                onChange={onChangeData}
                name="wit_id_known"
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

            {report.wit_id_known === "Yes" && (<>

            <h5>If yes, please provide the following information:</h5>

            <div className="form-group">
              <label htmlFor="wit_full_name">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="wit_full_name"
                value={report.wit_full_name}
                onChange={onChangeData}
                name="wit_full_name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="wit_gender">Gender</label>
              <select
                className="form-control"
                id="wit_gender"
                value={report.wit_gender}
                onChange={onChangeData}
                name="wit_gender"
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="wit_address">Address</label>
              <input
                type="text"
                className="form-control"
                id="wit_address"
                value={report.wit_address}
                onChange={onChangeData}
                name="wit_address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="wit_phone_no">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="wit_phone_no"
                value={report.wit_phone_no}
                onChange={onChangeData}
                name="wit_phone_no"
              />
            </div>

            </>)}
            </Row>

            <button onClick={saveReport} className="btn btn-success">
              Submit
            </button>
          </Container>
      </div>
    );
  
}
export default AddReport;