import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import SuggestionTableRow from "./SuggestionTableRow";
import ReactPaginate from 'react-paginate';
import './../paginate.css';
import moment from 'moment';

import axiosInstance from '../../services/axiosInstance';

const SuggestionList = () => {
  const [suggestion, setSuggestion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(5); // the number of page numbers to show at a time

  useEffect(() => {
    axiosInstance
      .get("/api/suggestions")
      .then(({ data }) => {
        // Assuming the data has a 'createdAt' field in a standard format
        const sortedData = data.sort((a, b) => {
          const dateA = moment(a.createdAt); // parse createdAt field with moment
          const dateB = moment(b.createdAt);
  
          return dateB.valueOf() - dateA.valueOf(); // sort in descending order
        });
  
        setSuggestion(sortedData);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const filteredSuggestion = suggestion.filter(suggestion => {
    const fullName = ` ${suggestion.name}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredSuggestion.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <SuggestionTableRow obj={res} key={i} />;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredSuggestion.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  } 

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }


  return (
    <Container>
      <h1>Suggestion List</h1>
      <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Suggestions</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {DataTable()}
        </tbody>
      </Table>

      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredSuggestion.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />

    </Container>
  );
};

export default SuggestionList;
