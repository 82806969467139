// EditReport Component for update report data

// Import Modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import axiosInstance from '../../services/axiosInstance';
import { useAuth } from '../Context/AuthContext'; // Import useAuth
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 const serverUrl = 'https://wxs.oav.mybluehost.me/reporting_app';
//const serverUrl = 'http://localhost:8084';


function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

// EditReport Component
const EditReport = () => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
   
  const { id } = useParams();
  // eslint-disable-next-line
  const[submitted, setSubmitted]=useState(false);
  const [divisions, setDivisions] = useState([]);
  const [stations, setStations] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(null);
  const {userId} = useAuth();
  const [formValues, setFormValues] = useState({
      ref_no:"",
      user_cat: "",
      first_name: "",
      last_name: "",
      other_names: "",
      gender: "",
      phone_number: "",
      email: "",
      street_address: "",
      village: "",
      region:"",
      report_category: "",
      from_date: "",
      from_time: "",
      to_date: "",
      to_time: "",
      inci_location: "",
      inci_street_address: "",
      inci_village: "",
      inci_district_name: "",
      inci_division: "",
      inci_region: "",
      sus_id_known: "",
      sus_first_name: "",
      sus_last_name: "",
      sus_aliases: "",
      sus_gender:"",
      sus_ethnicity:"",
      sus_dist_marks:"",
      sus_phone_number: "",
      sus_address: "",
      sus_vehicle_known: "",
      sus_license_plate_no: "",
      wit_id_known: "",
      wit_full_name: "",
      wit_gender: "",
      wit_address: "",
      wit_phone_no: "",
      file_1: "",
      file_1_description: "",
      report_status:"",
      last_edited_by:""
  });

 // console.log(`Current user Id: ${userId}`);

  const sectionStyle = {
   marginBottom: 40,
  };

  const renderMedia = (path) => {
    const extension = path.split('.').pop().toLowerCase();
    const isVideo = ['mp4', 'webm', 'ogg'].includes(extension);
    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);

    if (isVideo) {
      return (
        <video width="360" height="360" controls>
          <source src={`${serverUrl}/${path}`} type={`video/${extension}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (isImage) {
      return <img src={`${serverUrl}/${path}`} width="360" height="360" alt="Media" />;
    } else {
      return <p>Invalid file format.</p>;
    }
  };

  const handleChange = async event => {
    console.log('Handling change');
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDivisionListnChange = async (event) => {
    const { name, value } = event.target;
    

    if (name === "inci_region") {
      setFormValues({ ...formValues, [name]: value, division: ""  });

      const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${value}`);
      setDivisions(divisionsResponse.data);
      console.log(divisionsResponse.data); 
    }else {
    setFormValues({ ...formValues, [name]: value });
  }
  };

  const handleStationListnChange = async (event) => {
    const { name, value } = event.target;
  
    // Update the form value for the "Division" field and reset the "Station" field
    setFormValues({ ...formValues, [name]: value, station_name: "" });
  
    if (name === "inci_division") {
      try {
        // Make an API call to get the list of stations for the selected division
        const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${value}`);
  
        // Update the "stations" state variable with the stations returned by the API call
        setStations(stationsResponse.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitted(true)

    toast.success("Report updated successfully!", {
      onClose: () => navigate('/report_list') // Redirect to the home page when the toast is closed
    });
    try {
      const updatedFormValues = {
        ...formValues,
        last_edited_by: userId // Add this line
      };

     // console.log(updatedFormValues);
       // Capture changes
    const changes = Object.keys(formValues).reduce((acc, key) => {
      const initialValue = initialFormValues[key]; // You need to store the initialFormValues when the component loads
      const newValue = updatedFormValues[key];
      if (initialValue !== newValue) {
        acc.push({
          field: key,
          old: initialValue,
          new: newValue,
        });
      }
      return acc;
    }, []);

      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.put(
        `/api/reports/${id}`,
        updatedFormValues
      );

          // Record activity
    const activityData = {
      user_id: userId,
      activity_type: "Update Report",
      activity_details: JSON.stringify(changes),
      activity_time: new Date().toISOString(),
    };
    await axiosInstance.post(`/api/user_activities`, activityData);
    } catch (error) {
      // handle the error, such as displaying an error message
    }
  };

 // Load data from server and reinitialize student form
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/reports/${id}`
        );
        setFormValues(response.data);
        setInitialFormValues(response.data);

          // If the report has a region, fetch the corresponding divisions
      if (response.data.inci_region) {
          const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${response.data.inci_region}`);
          setDivisions(divisionsResponse.data);
          console.log(divisionsResponse.data);    
      }
      
      // If the report has a division, fetch the corresponding stations
      if (response.data.inci_division) {
        const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${response.data.inci_division}`);
        setStations(stationsResponse.data);
        console.log(stationsResponse.data);
      }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return report form
  return ( 
  <Container fluid>
 <ToastContainer />
    <form className="submit-form" onSubmit={handleSubmit}>
  
            <h1>View Report Record</h1>
          <Row style={sectionStyle}>

          <div className="form-group">
              <label htmlFor="first_name">Reference Number</label>
              <input
                type="text"
                className="form-control mb3"
                id="ref_no"
                value={formValues.ref_no|| ""}
                onChange={handleChange}
                name="ref_no"
                disabled
              />
            </div>

          </Row>

          <Row style={sectionStyle}>
          <div className="form-group">
              <label htmlFor="user_cat">Do you want to be Known?</label>
              <select
                className="form-control"
                id="user_cat"
                value={formValues.user_cat || ""}
                onChange={handleChange}
                name="user_cat"
                disabled
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

          </Row>
  

              <h4>Personal Details</h4>
      <Row style={sectionStyle}>

    
            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                value={formValues.first_name || ""}
                onChange={handleChange}
                name="first_name"
                disabled
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                value={formValues.last_name || ""}
                onChange={handleChange}
                name="last_name"
                disabled
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="other_names">Other Names</label>
              <input
                type="text"
                className="form-control"
                id="other_names"
                value={formValues.other_names || ""}
                onChange={handleChange}
                name="other_names"
                disabled
              />
            </div>
              </Col>
            </Row>
      

            <Row>
              <Col>
             <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                id="gender"
                value={formValues.gender || ""}
                onChange={handleChange}
                name="gender"
                disabled
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div> 
     

              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                value={formValues.phone_number || ""}
                onChange={handleChange}
                name="phone_number"
                disabled
              />
            </div>
              </Col>
            </Row>
            


            <div className="form-group">
              <label htmlFor="street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="street_address"
                value={formValues.street_address || ""}
                onChange={handleChange}
                name="street_address"
                disabled
              />
            </div>


          
              <Row>
                <Col>
                <div className="form-group">
              <label htmlFor="village">Village</label>
              <input
                type="text"
                className="form-control"
                id="village"
                value={formValues.village || ""}
                onChange={handleChange}
                name="village"
                disabled
              />
            </div>
                </Col>
                <Col>
              <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                id="region"
                value={formValues.region || ""}
                onChange={handleChange}
                name="region"
                disabled
              >
                <option value={0}>Choose a Region</option>
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
                </Col>
              </Row>

        </Row>
        <Row style={sectionStyle}>
            <h4>What Happened?</h4> 

            <div className="form-group">
              <label htmlFor="report_category">Report Category</label>
              <select
                className="form-control"
                id="report_category"
                value={formValues.report_category || ""}
                onChange={handleChange}
                name="report_category"
                disabled
              >
                <option>Please select an option</option>
                <option>General Crime</option>
                <option>Domestic Violence</option>
                <option>Noise Nuisance</option>
                <option>Death</option>
                <option>Sexual Assault</option>
              </select>
            </div>

            <label htmlFor="file_1">
                    Upload photo or video evidence of the incident
                  </label>
                <div style={sectionStyle}>{renderMedia(formValues.file_1)}</div>

            <div className="form-group">
              <label htmlFor="file_1_description">Describe the incident</label>
              <textarea
                type="text"
                className="form-control"
                id="file_1_description"
                value={formValues.file_1_description || ""}
                onChange={handleChange}
                name="file_1_description"
                disabled
              />
            </div>
        </Row>

            <h4>When Did It Happen?</h4>
        <Row style={sectionStyle}>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="from_date">From Date</label>
                  <input
                      type="text"
                      className="form-control"
                      id="from_date"
                      value={formValues.from_date ? formatDate(formValues.from_date) : ""}
                      onChange={handleChange}
                      name="from_date"
                      disabled
                    />

                </div>
              </Col>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="from_time">From Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="from_time"
                    value={formValues.from_time}
                    onChange={handleChange}
                    name="from_time"
                    disabled
                  />
                </div>
              </div>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="to_date">To Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="to_date"
                    value={formValues.to_date ? formatDate(formValues.to_date) : ""}
                    onChange={handleChange}
                    name="to_date"
                    disabled
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="to_time">To Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="to_time"
                    value={formValues.to_time || ""}
                    onChange={handleChange}
                    name="to_time"
                    disabled
                  />
                </div>
              </Col>
            </Row>
      </Row>

      <Row style={sectionStyle}>
            <h4>Where Did It Happen?</h4>

            <div className="form-group">
              <label htmlFor="inci_location">
                Describe the location where the
                incident happened
              </label>
              <input
                type="text"
                className="form-control"
                id="inci_location"
                value={formValues.inci_location || ""}
                onChange={handleChange}
                name="inci_location"
                disabled
              />
            </div>
            <Row>
            <Col>
            <div className="form-group">
              <label htmlFor="inci_street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="inci_street_address"
                value={formValues.inci_street_address || ""}
                onChange={handleChange}
                name="inci_street_address"
                disabled
              />
            </div>
            </Col>
            <Col>
                <div className="form-group">
              <label htmlFor="inci_village">Village</label>
              <input
                type="text"
                className="form-control"
                id="inci_village"
                value={formValues.inci_village || ""}
                onChange={handleChange}
                name="inci_village"
                disabled
              />
            </div>
                </Col>
                </Row>
              <Row>

                <Col>
                <div className="form-group">
                  <label htmlFor="inci_district_name">
                    Station Name
                  </label>
                  <select
                    className="form-control"
                    id="inci_district_name"
                    value={formValues.inci_district_name ||""}
                    onChange={handleChange}
                    name="inci_district_name"
                  >
                   <option value="">Select a Station</option>
                    {stations.map((station, index) => (
                      <option key={index} value={station.station_name}>
                        {station.station_name}
                      </option>
                    ))}
                  </select>
                </div>
                </Col>
                <Col>
                <div className="form-group">
                  <label htmlFor="inci_division">
                    Division
                  </label>
                  <select
            
                    className="form-control"
                    id="inci_division"
                    value={formValues.inci_division ||""}
                    onChange={handleStationListnChange}
                    name="inci_division"
                  >
                    <option value="">Select a division</option>
                    {divisions.map((division, index) => (
                      <option key={index} value={division.div_id}>
                        {division.div_name}
                      </option>
                    ))}
                  </select>
                </div>
                </Col>
                <Col>
                {/* <div className="form-group">
                  <label htmlFor="regions">Region</label>
                  <Dropdown className="form-control" options={regions} onChange={_onSelect} placeholder='Please select an option' />
                </div> */}
                <div className="form-group">
              <label htmlFor="inci_region">Region</label>
              <select
                className="form-control"
                id="inci_region"
                value={formValues.inci_region || ""}
                onChange={handleDivisionListnChange}
                name="inci_region"
                
              >
              <option value="">Select Region</option>
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>

                </Col>
              </Row>

      </Row>

      <Row style={sectionStyle}>

            <h4>Who Was Involved?</h4>

            <div className="form-group">
              <label htmlFor="sus_id_known">Do you know the Suspect ?</label>
              <select
                className="form-control"
                id="sus_id_known"
                value={formValues.sus_id_known || ""}
                onChange={handleChange}
                name="sus_id_known"
                disabled
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

          

            <h5>If yes, please provide the following information:</h5>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="sus_first_name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_first_name"
                    value={formValues.sus_first_name || ""}
                    onChange={handleChange}
                    name="sus_first_name"
                    disabled
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="sus_last_name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_last_name"
                    value={formValues.sus_last_name || ""}
                    onChange={handleChange}
                    name="sus_last_name"
                    disabled
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="sus_aliases">Aliases</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sus_aliases"
                    value={formValues.sus_aliases || ""}
                    onChange={handleChange}
                    name="sus_aliases"
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="sus_gender">Gender</label>
              <select
                className="form-control"
                id="sus_gender"
                value={formValues.sus_gender || ""}
                onChange={handleChange}
                name="sus_gender"
                disabled
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div> 
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="sus_ethnicity">Ethnicity</label>
              <select
                className="form-control"
                id="sus_ethnicity"
                value={formValues.sus_ethnicity || ""}
                onChange={handleChange}
                name="sus_ethnicity"
                disabled
              >
                <option>Please select an option</option>
                <option>Indian</option>
                <option>African</option>
                <option>Amerindian</option>
                <option>European</option>
                <option>Chinese</option>
                <option>Mixed</option>
                <option>Other</option>
              </select>
            </div> 
              </Col>
            </Row>

            <div className="form-group">
              <label htmlFor="sus_dist_marks">Distinct Marks '(' Scars, Birthmarks, Moles, tattoos etc ')'</label>
              <textarea
                type="text"
                className="form-control"
                id="sus_dist_marks"
                value={formValues.sus_dist_marks || ""}
                onChange={handleChange}
                name="sus_dist_marks"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="sus_phone_number"
                value={formValues.sus_phone_number || ""}
                onChange={handleChange}
                name="sus_phone_number"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_address">Address</label>
              <input
                type="text"
                className="form-control"
                id="sus_address"
                value={formValues.sus_address || ""}
                onChange={handleChange}
                name="sus_address"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="sus_vehicle_known">
                Do you know if the person(s) who did this used a vehicle?
              </label>
              <select
                className="form-control"
                id="sus_vehicle_known"
                value={formValues.sus_vehicle_known || ""}
                onChange={handleChange}
                name="sus_vehicle_known"
                disabled
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>

      


            <h5>If yes, please provide the following information:</h5>

            <div className="form-group">
              <label htmlFor="sus_license_plate_no">
                License Plate Number
              </label>
              <input
                type="text"
                className="form-control"
                id="sus_license_plate_no"
                value={formValues.sus_license_plate_no || ""}
                onChange={handleChange}
                name="sus_license_plate_no"
                disabled
              />
            </div>


            <div className="form-group">
              <label htmlFor="wit_id_known">
                Is there anyone else we could contact who saw what happened?
              </label>
              <select
                className="form-control"
                id="wit_id_known"
                value={formValues.wit_id_known || ""}
                onChange={handleChange}
                name="wit_id_known"
                disabled
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>


            <h5>If yes, please provide the following information:</h5>

            <div className="form-group">
              <label htmlFor="wit_full_name">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="wit_full_name"
                value={formValues.wit_full_name || ""}
                onChange={handleChange}
                name="wit_full_name"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="wit_gender">Gender</label>
              <select
                className="form-control"
                id="wit_gender"
                value={formValues.wit_gender || ""}
                onChange={handleChange}
                name="wit_gender"
                disabled
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="wit_address">Address</label>
              <input
                type="text"
                className="form-control"
                id="wit_address"
                value={formValues.wit_address || ""}
                onChange={handleChange}
                name="wit_address"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="wit_phone_no">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="wit_phone_no"
                value={formValues.wit_phone_no || ""}
                onChange={handleChange}
                name="wit_phone_no"
                disabled
              />
            </div>

      </Row>
  
      <Row style={sectionStyle}>
           <div className="form-group">
              <label htmlFor="wit_phone_no">Report Status</label>
              <select
                type="text"
                className="form-control"
                id="report_status"
                value={formValues.report_status || ""}
                onChange={handleChange}
                name="report_status"
               
              >
                <option>Pending</option>
                <option>Under Investigation</option>
                <option>Assigned</option>
                <option>Arrest Made</option>
                <option>Charges Filed</option>
                <option>Pending Trial</option>
                <option>Closed: Unfounded</option>
                <option>Closed: Insufficient Evidence</option>
                <option>Closed: Prosecution Declined</option>
                <option>Closed: Conviction</option>
                <option>Closed: Acquittal</option>
                <option>Closed: Case Dismissed</option>
                <option>Suspended</option>
              </select>
            </div>

      </Row>
      {/* <div className="form-group">
              <label htmlFor="last_edited_by">Last Edited By</label>
              <input
                type="text"
                className="form-control mb3"
                id="user_id"
                value={formValues.last_edited_by || ""}
                onChange={handleChange}
                name="last_edited_by"
                disabled
              />
            </div> */}

            <button type="submit" className="btn btn-success">Submit</button>
            <button type="button" className="btn btn-secondary" onClick={() => window.print()}>
          Print
        </button>
        </form>
        </Container>
    
  );
};

// Export EditReport Component
export default EditReport;
