import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import { Container} from "react-bootstrap";
import ReportTableRow from "./ReportTableRow";

import ReactPaginate from 'react-paginate';
import './../paginate.css';
import moment from 'moment';

import axiosInstance from '../../services/axiosInstance';
import { useAuth } from '../Context/AuthContext'; // Import useAuth

function ReportList() {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(1); // the number of page numbers to show at a time
  const {userRole, userRegion} = useAuth();

// console.log(stationName);


  useEffect(() => {

    const fetchReports = async () => {
      try {
        let response;
        if(userRole==="Admin" || userRole==="Data Entry"){
          response = await axiosInstance.get("/api/reports");
        } else {
          response = await axiosInstance.get(`/api/reports/reportRegion/${userRegion}`);
        }
  
        // Sort reports by date, oldest first.
        const sortedReports = response.data.sort((a, b) => {
               // Use moment to parse the dates
               const dateA = moment(a.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Adjust the format string to match your timestamp format
               const dateB = moment(b.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Adjust the format string to match your timestamp format
       
               // Compare the moment objects
               return dateB.valueOf() - dateA.valueOf(); // this sorts newest to oldest, considering both date and time
        });
  
        setReports(sortedReports);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchReports();
  }, [userRole, userRegion]);


  const filteredReports = reports.filter(report => {
    const searchFields = [
      report.ref_no,
      report.first_name,
      report.last_name,
      report.report_category,
      report.region?.toString(),  // Convert to string to perform the includes check
      report.inci_region?.toString(),  // Convert to string to perform the includes check
      report.gender
    ];
  
    // Normalize each field before checking if the search term is included
    return searchFields.some(field =>
      field
        ? field.toLowerCase().includes(searchTerm.toLowerCase())
        : false
    );
  });
  

  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredReports.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <ReportTableRow obj={res} key={i} />;
    });
  };
   
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredReports.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }


  return (
    <>
      <Container>
      
        <h1>Report List</h1>
        <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Ref Id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Other Names</th>
              <th>Gender</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Street Address</th>
              <th>Village</th>
              <th>Region</th>
              <th>Report Category</th>
              <th>From</th>
              <th>Time</th>
              <th>To</th>
              <th>Time</th>
              <th>Incident Location Description</th>
              <th>Street Address</th>             
              <th>Village</th>
              <th>Station Name</th>
              <th>Division</th>
              <th>Region</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Aliases</th>
              <th>Gender</th>
              <th>Ethnicity</th>
              <th>Distinct Marks</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Vehicle Known</th>
              <th>License Plate Number</th>
              <th>Vehicle Description</th>
              <th>Known Witness</th>
              <th>Full Name</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Photo/Video</th>
              <th>Description</th>
              <th>Report Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {DataTable()}
          </tbody>
        </Table>

      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredReports.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />
    
      </Container>
    </>
  );
}
export default ReportList;
