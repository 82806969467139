// ViewComplaint Component for update student data

// Import Modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import {  Col, Row} from "react-bootstrap";
import axiosInstance from '../../services/axiosInstance';
import { useAuth } from '../Context/AuthContext'; // Import useAuth
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

// ViewComplaint Component
const ViewComplaint = () => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function

  const { id } = useParams();
  // eslint-disable-next-line
  const[submitted, setSubmitted]=useState(false);
  const [divisions, setDivisions] = useState([]);
  const [stations, setStations] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(null);
  const {userId} = useAuth();
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    other_names: "",
    gender: "",
    phone_number: "",
    email: "",
    street_address: "",
    village: "",
    region: "",
    vic_name: "",
    vic_age: "",
    vic_gender: "",
    incident: "",
    inci_date: "",
    inci_time: "",
    inci_location: "",
    inci_address: "",
    inci_village: "",
    inci_district_name: "",
    inci_division:"",
    inci_region: "",
    inj_or_dam_known: "",
    inj_or_dam_desc: "",
    wit_known: "",
    wit_desc: "",
    inci_reported: "",
    given_ref: "",
    police_name: "",
    rank_or_desc: "",
    other_id: "",
    attached_station: "",
    remarks:"",
    last_edited_by:"",
  });

  const sectionStyle = {
    marginBottom: 40,
   };
 
  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDivisionListnChange = async (event) => {
    const { name, value } = event.target;
    

    if (name === "inci_region") {
      setFormValues({ ...formValues, [name]: value, division: ""  });

      const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${value}`);
      setDivisions(divisionsResponse.data);
      console.log(divisionsResponse.data); 
    }else {
    setFormValues({ ...formValues, [name]: value });
  }
  };

  const handleStationListnChange = async (event) => {
    const { name, value } = event.target;
  
    // Update the form value for the "Division" field and reset the "Station" field
    setFormValues({ ...formValues, [name]: value, station_name: "" });
  
    if (name === "inci_division") {
      try {
        // Make an API call to get the list of stations for the selected division
        const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${value}`);
  
        // Update the "stations" state variable with the stations returned by the API call
        setStations(stationsResponse.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitted(true)

    toast.success("Complaint updated successfully!", {
      onClose: () => navigate('/complaint_list') // Redirect to the home page when the toast is closed
    });
    
    try {

      const updatedFormValues = {
        ...formValues,
        last_edited_by: userId // Add this line
      };

      console.log(updatedFormValues);
      // Capture changes
   const changes = Object.keys(formValues).reduce((acc, key) => {
     const initialValue = initialFormValues[key]; // You need to store the initialFormValues when the component loads
     const newValue = updatedFormValues[key];
     if (initialValue !== newValue) {
       acc.push({
         field: key,
         old: initialValue,
         new: newValue,
       });
     }
     return acc;
   }, []);

      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.put(
        `/api/complaints/${id}`,
        updatedFormValues
      );
      const activityData = {
        user_id: userId,
        activity_type: "Update Complaint",
        activity_details: JSON.stringify(changes),
        activity_time: new Date().toISOString(),
      };
      await axiosInstance.post(`/api/user_activities`, activityData);
    } catch (error) {
      // handle the error, such as displaying an error message
    }
  };


  // Load data from server and reinitialize student form
  useEffect(() => {
      async function fetchData() {
        try {
          const response = await axiosInstance.get(
            `/api/complaints/${id}`
          );
          setFormValues(response.data);
          setInitialFormValues(response.data);

          if (response.data.inci_region) {
            const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${response.data.inci_region}`);
            setDivisions(divisionsResponse.data);
            console.log(divisionsResponse.data);    
        }
        
        // If the report has a division, fetch the corresponding stations
        if (response.data.inci_division) {
          
          const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${response.data.inci_division}`);
          setStations(stationsResponse.data);
         console.log(stationsResponse.data);
        }

        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

  // Return report form
  
  return (
    <Container fluid>
    <ToastContainer />
    <form className="submit-form" onSubmit={handleSubmit}>
 
     <div>
        <h1>View Complaint Record</h1>
        <h4>Personal Details</h4> 

        <Row style={sectionStyle}>
        <Row>
          <Col>
          <div className="form-group">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            value={formValues.first_name || ""}
            onChange={handleChange}
            name="first_name"
            disabled
          />
        </div>
          </Col>
          <Col>
          <div className="form-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="last_name"
            value={formValues.last_name || ""}
            onChange={handleChange}
            name="last_name"
            disabled
          />
        </div>
          </Col>
          <Col>
          <div className="form-group">
          <label htmlFor="other_names">Other Names</label>
          <input
            type="text"
            className="form-control"
            id="other_names"
            value={formValues.other_names || ""}
            onChange={handleChange}
            name="other_names"
            disabled
          />
        </div>
          </Col>
        </Row>
  

        <Row>
          <Col>
         <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <select
            className="form-control"
            id="gender"
            value={formValues.gender || ""}
            onChange={handleChange}
            name="gender"
            disabled
          >
            <option>Please select an option</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div> 
 

          </Col>
          <Col>
          <div className="form-group">
          <label htmlFor="phone_number">Phone Number</label>
          <input
            type="text"
            className="form-control"
            id="phone_number"
            value={formValues.phone_number || ""}
            onChange={handleChange}
            name="phone_number"
            disabled
          />
        </div>
          </Col>
        </Row>
        


        <div className="form-group">
          <label htmlFor="street_address">Street Address</label>
          <input
            type="text"
            className="form-control"
            id="street_address"
            value={formValues.street_address || ""}
            onChange={handleChange}
            name="street_address"
            disabled
          />
        </div>



          <Row>
            <Col>
            <div className="form-group">
          <label htmlFor="village">Village</label>
          <input
            type="text"
            className="form-control"
            id="village"
            value={formValues.village || ""}
            onChange={handleChange}
            name="village"
            disabled
          />
        </div>
            </Col>
            <Col>
          <div className="form-group">
          <label htmlFor="region">Region</label>
          <select
            className="form-control"
            id="region"
            value={formValues.region || ""}
            onChange={handleChange}
            name="region"
            disabled
          >
            <option value={0}>Choose a Region</option>
            <option value={1}>Region 1 Barima-Waini</option>
            <option value={2}>Region 2 Pomeroon-Supenaam</option>
            <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
            <option value={4}>Region 4 Demerara-Mahaica</option>
            <option value={5}>Region 5 Mahaica-Berbice</option>
            <option value={6}>Region 6 East Berbice-Corentyne</option>
            <option value={7}>Region 7 Cuyuni-Mazaruni</option>
            <option value={8}>Region 8 Potaro-Siparuni</option>
            <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
            <option value={10}>Region 10 Upper Demerara-Berbice</option>
          </select>
        </div>
            </Col>
          </Row>
        </Row>
        



          <h4>Victim's Details</h4> 

        <Row style={sectionStyle}>
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="vic_name">Victims Name</label>
              <input
                type="text"
                className="form-control"
                id="vic_name"
                value={formValues.vic_name || ""}
                onChange={handleChange}
                name="vic_name"
                disabled
              />
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label htmlFor="vic_age">Age</label>
              <input
                type="text"
                className="form-control"
                id="vic_age"
                value={formValues.vic_age || ""}
                onChange={handleChange}
                name="vic_age"
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="vic_gender">Gender</label>
              <select
                className="form-control"
                id="vic_gender"
                value={formValues.vic_gender || ""}
                onChange={handleChange}
                name="vic_gender"
                disabled
              >
                <option>----Please select an option----</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
          </Col>
          
        </Row>
        </Row>

        <h4>What Happened?</h4> 

        <Row style={sectionStyle}>
        <div className="form-group">
          <label htmlFor="incident">Describe Incident</label>
          <textarea
            type="text"
            className="form-control"
            id="incident"
            value={formValues.incident || ""}
            onChange={handleChange}
            name="incident"
            disabled
          />
        </div>
        </Row>


        <h4>When Did It Happened?</h4> 

        <Row style={sectionStyle}>
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="inci_date">Incident Date</label>
              <input
                type="text"
                className="form-control"
                id="inci_date"
                value={formValues.inci_date ? formatDate(formValues.inci_date) : ""}
                onChange={handleChange}
                name="inci_date"
                disabled
              />
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label htmlFor="inci_time">Incident Time</label>
              <input
                type="time"
                className="form-control"
                id="inci_time"
                value={formValues.inci_time || ""}
                onChange={handleChange}
                name="inci_time"
                disabled
              />
            </div>
          </Col>
        </Row>
        </Row>


        <h4>Where did it Happen?</h4>

        <Row style={sectionStyle}>
        <div className="form-group">
          <label htmlFor="inci_location">Describe the Location the incident occured</label>
          <textarea
            type="text"
            className="form-control"
            id="inci_location"
            value={formValues.inci_location || ""}
            onChange={handleChange}
            name="inci_location"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="inci_address">Address</label>
          <input
            type="text"
            className="form-control"
            id="inci_address"
            value={formValues.inci_address || ""}
            onChange={handleChange}
            name="inci_address"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="inci_village">Village</label>
          <input
            type="text"
            className="form-control"
            id="inci_village"
            value={formValues.inci_village || ""}
            onChange={handleChange}
            name="inci_village"
            disabled
          />
        </div>

        <Row>
        <Col>
                <div className="form-group">
                  <label htmlFor="inci_district_name">
                    Station Name
                  </label>
                  <select
                    className="form-control"
                    id="inci_district_name"
                    value={formValues.inci_district_name ||""}
                    onChange={handleChange}
                    name="inci_district_name"
                  >
                   <option value="">Select a Station</option>
                    {stations.map((station, index)=> (
                      <option key={index} value={station.station_name}>
                        {station.station_name}
                      </option>
                    ))}
                  </select>
                </div>
                </Col>
                <Col>
                <div className="form-group">
                  <label htmlFor="inci_division">
                    Division
                  </label>
                  <select           
                    className="form-control"
                    id="inci_division"
                    value={formValues.inci_division ||""}
                    onChange={handleStationListnChange}
                    name="inci_division"
                  >
                    <option value="">Select a division</option>
                    {divisions.map((division, index) => (
                      <option key={index} value={division.div_id}>
                        {division.div_name}
                      </option>
                    ))}
                  </select>
                </div>
                </Col>
          <Col>
          <div className="form-group">
          <label htmlFor="inci_region">Region</label>
          <select
            className="form-control"
            id="inci_region"
            value={formValues.inci_region ||""}
            onChange={handleDivisionListnChange}
            name="inci_region"
          >
            <option value={0}>Choose a Region</option>
            <option value={1}>Region 1 Barima-Waini</option>
            <option value={2}>Region 2 Pomeroon-Supenaam</option>
            <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
            <option value={4}>Region 4 Demerara-Mahaica</option>
            <option value={5}>Region 5 Mahaica-Berbice</option>
            <option value={6}>Region 6 East Berbice-Corentyne</option>
            <option value={7}>Region 7 Cuyuni-Mazaruni</option>
            <option value={8}>Region 8 Potaro-Siparuni</option>
            <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
            <option value={10}>Region 10 Upper Demerara-Berbice</option>
          </select>
        </div>
          </Col>
        </Row>
        </Row>


        <h4>Injuries or Damages</h4>

        <Row style={sectionStyle}>
        <div className="form-group">
          <label htmlFor="inj_or_dam_known">
            Was there any injury or damages?
          </label>
          <select
            className="form-control"
            id="inj_or_dam_known"
            value={formValues.inj_or_dam_known || ""}
            onChange={handleChange}
            name="inj_or_dam_known"
            disabled
          >
            <option>Please select an option</option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="inj_or_dam_desc">If yes, describe</label>
          <textarea
            type="text"
            className="form-control"
            id="inj_or_dam_desc"
            value={formValues.inj_or_dam_desc || ""}
            onChange={handleChange}
            name="inj_or_dam_desc"
            disabled
          />
        </div>
        </Row>




        <h4>Known Witness</h4>

        <Row style={sectionStyle}>
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="wit_known">
                Were there any witnesses present?
              </label>
              <select
                className="form-control"
                id="wit_known"
                value={formValues.wit_known || ""}
                onChange={handleChange}
                name="wit_known"
                disabled
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </Col>

          <div className="form-group">
            <label htmlFor="wit_desc">If yes, describe</label>
            <textarea
              type="email"
              className="form-control"
              id="wit_desc"
              value={formValues.wit_desc || ""}
              onChange={handleChange}
              name="wit_desc"
              disabled
            />
          </div>
        </Row>

     
        </Row>


        <h4>Officer Details</h4>

        <Row style={sectionStyle}>
        <div className="form-group">
          <label htmlFor="inci_reported">
            Have you reported the incident to any police station?
          </label>
          <input
            type="text"
            className="form-control"
            id="inci_reported"
            value={formValues.inci_reported || ""}
            onChange={handleChange}
            name="inci_reported"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="given_ref">If yes, provide reference.</label>
          <textarea
            type="text"
            className="form-control"
            id="given_ref"
            value={formValues.given_ref || ""}
            onChange={handleChange}
            name="given_ref"
            disabled
          />
        </div>     

        <div className="form-group">
          <label htmlFor="police_name">
            Name/s of Police Officer/s against whom a complaint is made
          </label>
          <textarea
            type="text"
            className="form-control"
            id="police_name"
            value={formValues.police_name || ""}
            onChange={handleChange}
            name="police_name"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="rank_or_desc">Rank Description</label>
          <textarea
            type="text"
            className="form-control"
            id="rank_or_desc"
            value={formValues.rank_or_desc || ""}
            onChange={handleChange}
            name="rank_or_desc"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="other_id">Other Means of Identification</label>
          <textarea
            type="text"
            className="form-control"
            id="other_id"
            value={formValues.other_id || ""}
            onChange={handleChange}
            name="other_id"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="attached_station">
            Details of the unit where the police officer work or is
            attached.
          </label>
          <textarea
            type="text"
            className="form-control"
            id="attached_station"
            value={formValues.attached_station || ""}
            onChange={handleChange}
            name="attached_station"
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="remarks">
           Remarks
          </label>
          <textarea
            type="text"
            className="form-control"
            id="remarks"
            value={formValues.remarks|| ""}
            onChange={handleChange}
            name="remarks"
            disabled
          />
        </div>
        </Row>



        <button type="submit" className="btn btn-success">Submit</button>
        <button type="button" className="btn btn-secondary" onClick={() => window.print()}>
          Print
        </button>
      </div>
  </form>
  </Container>

  );
};

// Export ViewComplaint Component
export default ViewComplaint;
