import React from "react";
import { Link } from "react-router-dom";
//import axios from "axios";

import EditReport from "./edit-report.component";
const serverUrl = 'https://wxs.oav.mybluehost.me/reporting_app';
//const serverUrl = 'http://localhost:8084';

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

const ReportTableRow = props => {
  const {
    id,
    ref_no,
    first_name,
    last_name,
    other_names,
    gender,
    phone_number,
    email,
    street_address,
    village,
    region,
    report_category,
    from_date,
    from_time,
    to_date,
    to_time,
    inci_location_desc,
    inci_street_address,
    inci_district_name,
    inci_division,
    inci_village,
    inci_region,
    sus_first_name,
    sus_last_name,
    sus_aliases,
    sus_gender,
    sus_ethnicity,
    sus_dist_marks,
    sus_phone_number,
    sus_address,
    sus_vehicle_known,
    sus_license_plate_no,
    sus_vehicle_desc,
    wit_id_known,
    wit_full_name,
    wit_address,
    wit_phone_no,
    file_1,
    file_1_description,
    report_status
  } = props.obj;

  const renderMedia = (path) => {
    const extension = path.split('.').pop().toLowerCase();
    const isVideo = ['mp4', 'webm', 'ogg'].includes(extension);
    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);

    if (isVideo) {
      return (
        <video width="360" height="180" controls>
          <source src={`${serverUrl}/${path}`} type={`video/${extension}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (isImage) {
      return <img src={`${serverUrl}/${path}`} width="360" height="180" alt="Media" />;
    } else {
      return <p>Invalid file format.</p>;
    }
  };
  return (
    <tr>
      <td>
        {id}
      </td>
      <td>
        {ref_no}
      </td>
      <td>
        {first_name}
      </td>
      <td>
        {last_name}
      </td>
      <td>
        {other_names}
      </td>
      <td>
        {gender}
      </td>
      <td>
        {phone_number}
      </td>
      <td>
        {email}
      </td>
      <td>
        {street_address}
      </td>
      <td>
        {village}
      </td>
      <td>
        {region}
      </td>
      <td>
        {report_category}
      </td>
      <td>
        {from_date ? formatDate(from_date) : ""}
      </td>
      <td>
        {from_time}
      </td>
      <td>
        {to_date ? formatDate(to_date) : ""}
      </td>
      <td>
        {to_time}
      </td>
      <td>
        {inci_location_desc}
      </td>
      <td>
        {inci_street_address}
      </td>
      <td>
        {inci_village}
      </td>
      <td>
        {inci_district_name}
      </td>
      <td>
        {inci_division}
      </td>
      <td>
        {inci_region}
      </td>
      <td>
        {sus_first_name}
      </td>
      <td>
        {sus_last_name}
      </td>
      <td>
        {sus_aliases}
      </td>
      <td>
        {sus_gender}
      </td>
      <td>
        {sus_ethnicity}
      </td>
      <td>
        {sus_dist_marks}
      </td>
      <td>
        {sus_phone_number}
      </td>
      <td>
        {sus_address}
      </td>
      <td>
        {sus_vehicle_known}
      </td>
      <td>
        {sus_license_plate_no}
      </td>
      <td>
        {sus_vehicle_desc}
      </td>
      <td>
        {wit_id_known}
      </td>
      <td>
        {wit_full_name}
      </td>
      <td>
        {wit_address}
      </td>
      <td>
        {wit_phone_no}
      </td>
      <td> 
      {/* {<Image src={`${serverUrl}/${file_1}`} thumbnail/>} */}
      {renderMedia(file_1)}
      </td>
      <td>
        {file_1_description}
      </td>
      <td>
        {report_status}
      </td>

      <td>
        <Link
          className="edit-link"
          to={"/edit-report/" + id}
          element={<EditReport />}
        >
          View
        </Link>
      </td>
    </tr>
  );
};

export default ReportTableRow;
