import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { Col, Row, Card, Button } from "react-bootstrap";

function Welcome() {
  
  return (
    <><Container className="p-3 bg-light text-center">

      <Row>
      <Container >
          <h1>Welcome to Secure Eye</h1>
          <p>
          Secure Eye is a user-friendly app that allows you to file complaints and report incidents with ease. 
          Whether it's a lost item, theft, or any other criminal activity, Secure Eye is here to help. 
          Our app not only provides an easy platform to file complaints but also has a comprehensive list of nearby police stations, making it easier for you to connect with authorities promptly.
          With Secure Eye, you can rest assured that your safety and security are our topmost priority.
          </p>
          <hr />
          <p className="mb-0">
          "Your Safety, Our Responsibility"
          </p>
        </Container>
      </Row>
    
    </Container>
    <Container>
    <Row className="mb-3">
      <Col className="d-flex justify-content-center">
      <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Reporting_Icon.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Report a Crime</Card.Title>
              <Card.Text>
                Description.
              </Card.Text>
              <Button href="/add" variant="primary">
                File Report Here
              </Button>
            </Card.Body>
          </Card>
      </Col>
      <Col className="d-flex justify-content-center">
      <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Complaint_Icon.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Make a Complaint</Card.Title>
              <Card.Text>
                Description.
                </Card.Text>
                <Button href="/add-complaint" variant="primary">
                File Complaint Here
              </Button>
            </Card.Body>
          </Card>
      </Col>
      <Col className="d-flex justify-content-center">
      <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Search_Report.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Search Reports</Card.Title>
              <Card.Text>
              Description.
              </Card.Text>
              <Button href="/follow-up" variant="primary">
                Search Reports Here
              </Button>
            </Card.Body>
          </Card>
      </Col>

      
      </Row>
      <Row>
      <Col className="d-flex justify-content-center">
      <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Search_Station.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Search Stations</Card.Title>
              <Card.Text>
              Description.
              </Card.Text>
              <Button href="/station" variant="primary">
              Search Stations Here
              </Button>
            </Card.Body>
          </Card>
      </Col>
      <Col className="d-flex justify-content-center">
        <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Suggestion.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Leave a Suggestion</Card.Title>
              <Card.Text>
              Description.
              </Card.Text>
              <Button href="/add-suggestion" variant="primary">
              Leave a Suggestion Here
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-flex justify-content-center">
        <Card style={{ width: "15rem" }}>
            <Card.Body>
            <Card.Img src={process.env.PUBLIC_URL +'/Compliment.png'}  width="210" height="210"></Card.Img>
              <Card.Title>Leave a Compliment</Card.Title>
              <Card.Text>
              Description.
              </Card.Text>
              <Button href="/add-compliment" variant="primary">
              Leave a Compliment Here
              </Button>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container></>
  );
}
export default Welcome;
