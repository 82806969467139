import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useAuth } from '../Context/AuthContext'; // Import useAuth
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

const AddComplaint = (props) => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const {userId} = useAuth();
  const [complaint, setComplaint] = useState({

      id: null,
      first_name: "",
      last_name: "",
      other_names: "",
      gender: "",
      phone_number: "",
      email: "",
      street_address: "",
      village: "",
      region:1,
      vic_name: "",
      vic_age: "",
      vic_gender: "",
      incident: "",
      inci_date: new Date().toISOString().split('T')[0],
      inci_time: "",
      inci_location: "",
      inci_address: "",
      inci_village: "",
      inci_region: 1,
      inj_or_dam_known: "",
      inj_or_dam_desc: "",
      wit_known: "",
      wit_desc: "",
      inci_reported: "",
      given_ref: "",
      police_name: "",
      rank_or_desc: "",
      other_id: "",
      vehicle_no: "",
      attached_station: "",
      remarks: "",

      submitted: false
    });

    let InciDateFormatted = new Date(complaint.inci_date).toISOString().split('T')[0];

    const sectionStyle = {
      marginBottom: 40,
     };
  

  const saveComplaint = () => {

    const config = {
      headers: {
        "Content-Type": "application/json"

      }
    };

    const complaintWithUserId = {
      ...complaint,
      userId // Include userId in the complaint object
  };


    // ComplaintsDataService.create(data)
    axiosInstance.post('/api/complaints', complaintWithUserId, config)
      .then(response => {
        setComplaint({
          ...response.data,
          submitted: true

        });
   

        toast.success("Complaint sent successfully!", {
          onClose: () => navigate('/') // Redirect to the home page when the toast is closed
        });



           // Save activity after a successful complaint submission
           saveUserActivity(response.data);
      })
      .catch(e => {
        console.log(e);
        toast.error("Error sending the complaint."); // Display an error toast
      });
  }
  const saveUserActivity = (complaintData) => {
    const activity = {
        user_id: userId,
        activity_type: "Add Complaint",
        activity_details: JSON.stringify(complaintData), // Attach the entire complaint response data here
        activity_time: new Date().toISOString(),
     
    };

    axiosInstance.post('/api/user_activities', activity)
        .then(response => {
            console.log("User activity saved successfully.", response.data);
        })
        .catch(e => {
            console.log("Error saving user activity.", e);
        });
}


   // Define a function to handle the onChange for each field
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    setComplaint({ ...complaint, [name]: value });
  };

 
    return (
      <div className="submit-form">
      <ToastContainer />
       <div>
            <h1>Make a Complaint</h1>
            <h4>Personal Details</h4> 

            <Row style={sectionStyle}>
            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                value={complaint.first_name}
                onChange={handleInputChange}
                name="first_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                value={complaint.last_name}
                onChange={handleInputChange}
                name="last_name"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="other_names">Other Names</label>
              <input
                type="text"
                className="form-control"
                id="other_names"
                value={complaint.other_names}
                onChange={handleInputChange}
                name="other_names"
              />
            </div>
              </Col>
            </Row>
      

            <Row>
              <Col>
             <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                id="gender"
                value={complaint.gender}
                onChange={handleInputChange}
                name="gender"
              >
                <option>Please select an option</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div> 
     

              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                value={complaint.phone_number}
                onChange={handleInputChange}
                name="phone_number"
              />
            </div>
              </Col>
              <Col>
              <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={complaint.email}
                onChange={handleInputChange}
                name="email"
              />
            </div>
              </Col>
            </Row>
            


            <div className="form-group">
              <label htmlFor="street_address">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="street_address"
                value={complaint.street_address}
                onChange={handleInputChange}
                name="street_address"
              />
            </div>



              <Row>
                <Col>
                <div className="form-group">
              <label htmlFor="village">Village</label>
              <input
                type="text"
                className="form-control"
                id="village"
                value={complaint.village}
                onChange={handleInputChange}
                name="village"
              />
            </div>
                </Col>
                <Col>
              <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                className="form-control"
                id="region"
                value={complaint.region}
                onChange={handleInputChange}
                name="region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
                </Col>
              </Row>
            </Row>


           <h4>Victim's Details</h4> 

           <Row style={sectionStyle}>
           <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="vic_name">Victims Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="vic_name"
                    value={complaint.vic_name}
                    onChange={handleInputChange}
                    name="vic_name"
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="vic_age">Age</label>
                  <input
                    type="text"
                    className="form-control"
                    id="vic_age"
                    value={complaint.vic_age}
                    onChange={handleInputChange}
                    name="vic_age"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="vic_gender">Gender</label>
                  <select
                    className="form-control"
                    id="vic_gender"
                    value={complaint.vic_gender}
                    onChange={handleInputChange}
                    name="vic_gender"
                  >
                    <option>----Please select an option----</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
              </Col>
              
            </Row>
           </Row>


            <h4>What Happened?</h4> 

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="incident">Describe Incident</label>
              <textarea
                type="text"
                className="form-control"
                id="incident"
                value={complaint.incident}
                onChange={handleInputChange}
                name="incident"
              />
            </div>
            </Row>

            <h4>When Did It Happened?</h4> 

            <Row style={sectionStyle}>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="inci_date">Incident Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="inci_date"
                    value={InciDateFormatted}
                    onChange={handleInputChange}
                    name="inci_date"
                  />
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label htmlFor="inci_time">Incident Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="inci_time"
                    value={complaint.inci_time}
                    onChange={handleInputChange}
                    name="inci_time"
                  />
                </div>
              </Col>
            </Row>
            </Row>


            <h4>Where did it Happen?</h4>

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="inci_location">Describe the Location the incident occured</label>
              <textarea
                type="text"
                className="form-control"
                id="inci_location"
                value={complaint.inci_location}
                onChange={handleInputChange}
                name="inci_location"
              />
            </div>

            <div className="form-group">
              <label htmlFor="inci_address">Address</label>
              <input
                type="text"
                className="form-control"
                id="inci_address"
                value={complaint.inci_address}
                onChange={handleInputChange}
                name="inci_address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="inci_village">Village</label>
              <input
                type="text"
                className="form-control"
                id="inci_village"
                value={complaint.inci_village}
                onChange={handleInputChange}
                name="inci_village"
              />
            </div>

            <Row>

              <Col>
              <div className="form-group">
              <label htmlFor="inci_region">Region</label>
              <select
                className="form-control"
                id="inci_region"
                value={complaint.inci_region}
                onChange={handleInputChange}
                name="inci_region"
              >
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
            </div>
              </Col>
            </Row>
            </Row>


            <h4>Injuries or Damages</h4>

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="inj_or_dam_known">
                Was there any injury or damages?
              </label>
              <select
                className="form-control"
                id="inj_or_dam_known"
                value={complaint.inj_or_dam_known}
                onChange={handleInputChange}
                name="inj_or_dam_known"
              >
                <option>Please select an option</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            {complaint.inj_or_dam_known === "Yes" && (<>
            <div className="form-group">
              <label htmlFor="inj_or_dam_desc">If yes, describe</label>
              <textarea
                type="text"
                className="form-control"
                id="inj_or_dam_desc"
                value={complaint.inj_or_dam_desc}
                onChange={handleInputChange}
                name="inj_or_dam_desc"
              />
            </div>
           
            </>)}
            </Row>



            <h4>Known Witness</h4>

            <Row style={sectionStyle}>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="wit_known">
                    Were there any witnesses present?
                  </label>
                  <select
                    className="form-control"
                    id="wit_known"
                    value={complaint.wit_known}
                    onChange={handleInputChange}
                    name="wit_known"
                  >
                    <option>Please select an option</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </Col>

              {complaint.wit_known === "Yes" && (<>

              <div className="form-group">
                <label htmlFor="wit_desc">If yes, describe</label>
                <textarea
                  type="email"
                  className="form-control"
                  id="wit_desc"
                  value={complaint.wit_desc}
                  onChange={handleInputChange}
                  name="wit_desc"
                />
              </div>
              </>)}
            </Row>
            </Row>


            <h4>Officer Details</h4>

            <Row style={sectionStyle}>
            <div className="form-group">
              <label htmlFor="inci_reported">
                Have you reported the incident to any police station?
              </label>

              <select
                    className="form-control"
                    id="inci_reported"
                    value={complaint.inci_reported}
                    onChange={handleInputChange}
                    name="inci_reported"
                  >
                    <option>Please select an option</option>
                    <option>Yes</option>
                    <option>No</option>
                </select>
            </div>

            {complaint.inci_reported === "Yes" && (<>
            <div className="form-group">
              <label htmlFor="given_ref">If yes, provide reference ( Officer Name and Rank ).</label>
              <textarea
                type="text"
                className="form-control"
                id="given_ref"
                value={complaint.given_ref}
                onChange={handleInputChange}
                name="given_ref"
              />
            </div>
            </>)}

            <div className="form-group">
              <label htmlFor="police_name">
                Name/s of Police Officer/s against whom a complaint is made
              </label>
              <textarea
                type="text"
                className="form-control"
                id="police_name"
                value={complaint.police_name}
                onChange={handleInputChange}
                name="police_name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="rank_or_desc">Rank Description</label>
              <textarea
                type="text"
                className="form-control"
                id="rank_or_desc"
                value={complaint.rank_or_desc}
                onChange={handleInputChange}
                name="rank_or_desc"
              />
            </div>

            <div className="form-group">
              <label htmlFor="other_id">Other Means of Identification</label>
              <textarea
                type="text"
                className="form-control"
                id="other_id"
                value={complaint.other_id}
                onChange={handleInputChange}
                name="other_id"
              />
            </div>

            <div className="form-group">
              <label htmlFor="vehicle_no">Vehicle Number</label>
              <textarea
                type="text"
                className="form-control"
                id="vehicle_no"
                value={complaint.vehicle_no}
                onChange={handleInputChange}
                name="vehicle_no"
              />
            </div>

            <div className="form-group">
              <label htmlFor="attached_station">
                Details of the unit where the police officer work or is
                attached.
              </label>
              <textarea
                type="text"
                className="form-control"
                id="attached_station"
                value={complaint.attached_station}
                onChange={handleInputChange}
                name="attached_station"
              />
            </div>

            <div className="form-group">
              <label htmlFor="remarks">
              Remarks
              </label>
              <textarea
                type="text"
                className="form-control"
                id="remarks"
                value={complaint.remarks}
                onChange={handleInputChange}
                name="remarks"
              />
            </div>
            </Row>




            <button onClick={saveComplaint} className="btn btn-success">
              Submit
            </button>
          </div>
      </div>
    );
  
}
export default AddComplaint;