import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Container, Form} from "react-bootstrap";
import { Button } from "react-bootstrap";
import UserTableRow from "./UserTableRow";
import AddUser from "./add-user.component";
import ReactPaginate from 'react-paginate';
import './../paginate.css';

import axiosInstance from '../../services/axiosInstance';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLimit] = useState(5); // the number of page numbers to show at a time

  useEffect(() => {
    axiosInstance
      .get("/api/users")
      .then(({ data }) => {
        setUsers(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);


  const filteredUsers = users.filter(user => {
    const searchFields = [
      user.first_name,
      user.last_name,
      user.station_name,
      user.division, // Ensure that the property name is spelled correctly. You had 'divsion' in your code snippet.
      user.region?.toString() // Convert to string to perform the includes check
    ];
  
    // Normalize each field before checking if the search term is included
    return searchFields.some(field =>
      field
        ? field.toLowerCase().includes(searchTerm.toLowerCase())
        : false
    );
  });
  

  const DataTable = () => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = filteredUsers.slice(firstIndex, lastIndex);

    return currentItems.map((res, i) => {
      return <UserTableRow obj={res} key={i} />;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredUsers.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  } 

  return (
    <Container>
      <h1>User List</h1>
      <Button variant="secondary">
        <Link to={"/register"} element={<AddUser />} className="nav-link">
          Create New User
        </Link>
      </Button>
      <Form>
          <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </Form>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Other Names</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Station Name</th>
            <th>Division</th>
            <th>Region</th>
            <th>User Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {DataTable()}
        </tbody>
      </Table>
   
      <ReactPaginate
        previousLabel={"first"}
        nextLabel={"last"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredUsers.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageLimit}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination-container"}
        subContainerClassName={"pagination-subcontainer"}
        activeClassName={"pagination-active"}
        pageClassName={"pagination-page"}
        previousClassName={"pagination-prev"}
        nextClassName={"pagination-next"}
      />

    </Container>
  );
};

export default UserList;
