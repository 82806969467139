import React from "react";
//import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import axios from "axios";

import ViewComplaint from "./view-complaint.component";

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}


const ComplaintTableRow = props => {
  const {
    id,
    user_cat,
    first_name,
    last_name,
    other_names,
    gender,
    phone_number,
    email,
    street_address,
    village,
    region,
    vic_name,
    vic_age,
    vic_gender,
    incident,
    inci_date,
    inci_time,
    inci_location,
    inci_address,
    inci_village,
    inci_division,
    inci_region,
    inj_or_dam_known,
    inj_or_dam_desc,
    wit_known,
    wit_desc,
    inci_reported,
    given_ref,
    police_name,
    rank_or_desc,
    other_id,
    attached_station
  } = props.obj;

  return (
    <tr>
      <td>
        {user_cat}
      </td>
      <td>
        {first_name}
      </td>
      <td>
        {last_name}
      </td>
      <td>
        {other_names}
      </td>
      <td>
        {gender}
      </td>
      <td>
        {phone_number}
      </td>
      <td>
        {email}
      </td>
      <td>
        {street_address}
      </td>
      <td>
        {village}
      </td>
      <td>
        {region}
      </td>
      <td>
        {vic_name}
      </td>
      <td>
        {vic_age}
      </td>
      <td>
        {vic_gender}
      </td>
      <td>
        {incident}
      </td>
      <td>
      {inci_date ? formatDate(inci_date) : ""}
      </td>
      <td>
        {inci_time}
      </td>
      <td>
        {inci_location}
      </td>
      <td>
        {inci_address}
      </td>
      <td>
        {inci_village}
      </td>
      <td>
        {inci_division}
      </td>
      <td>
        {inci_region}
      </td>
      <td>
        {inj_or_dam_known}
      </td>
      <td>
        {inj_or_dam_desc}
      </td>
      <td>
        {wit_known}
      </td>
      <td>
        {wit_desc}
      </td>
      <td>
        {inci_reported}
      </td>
      <td>
        {given_ref}
      </td>
      <td>
        {police_name}
      </td>
      <td>
        {rank_or_desc}
      </td>
      <td>
        {other_id}
      </td>
      <td>
        {attached_station}
      </td>

      <td>
        <Link
          className="edit-link"
          to={"/view-complaint/" + id}
          element={<ViewComplaint />}
        >
          View
        </Link>
      </td>
    </tr>
  );
};

export default ComplaintTableRow;
