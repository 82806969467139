import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from '../Context/AuthContext'; // Import useAuth 

import EditStation from "./edit-station.component";
import axiosInstance from '../../services/axiosInstance';

const StationTableRow = props => {
  const { isLoggedIn } = useAuth(); // Get isLoggedIn and logout from the AuthContext
  const { id, station_name, location, phone_no, div_id, reg_id } = props.obj;

  const deleteStation = () => {
    axiosInstance
      .delete("/api/stations/" + id)
      .then(res => {
        if (res.status === 200) {
          console.log("Station successfully deleted");
          window.location.reload();
        } else Promise.reject();
      })
      .catch(err => console.log("Something went wrong"));
  };

  return (
    <tr>
      <td>
        {station_name}
      </td>
      <td>
        {location}
      </td>
      <td>
        {phone_no}
      </td>
      <td>
        {div_id}
      </td>
      <td>
        {reg_id}
      </td>
      {isLoggedIn ?(
        <td>
        <Link
          className="edit-link"
          to={"/edit-station/" + id}
          element={<EditStation />}
        >
          Edit
        </Link>
        <br />
        <br />

        <Button onClick={deleteStation} size="sm" variant="danger">
          Delete
        </Button>
      </td>
      ):(<p></p>)}
     
    </tr>
  );
};

export default StationTableRow;
