// EditUser Component for update user data

// Import Modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

// EditUser Component
const EditUser = () => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const[submitted, setSubmitted]=useState(false);
  const [divisions, setDivisions] = useState([]);
  const [stations, setStations] = useState([]);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    other_names: "",
    gender: "",
    date_of_birth: "",
    email: "",
    phone_number: "",
    station_name: "",
    division:"",
    region: "",
    user_role: ""
  });

    
  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDivisionListnChange = async (event) => {
    const { name, value } = event.target;
    

    if (name === "region") {
      setFormValues({ ...formValues, [name]: value, division: ""  });

      const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${value}`);
      setDivisions(divisionsResponse.data);
      console.log(divisionsResponse.data); 
    }else {
    setFormValues({ ...formValues, [name]: value });
  }
  };

  const handleStationListnChange = async (event) => {
    const { name, value } = event.target;
  
    // Update the form value for the "Division" field and reset the "Station" field
    setFormValues({ ...formValues, [name]: value, station_name: "" });
  
    if (name === "division") {
      try {
        // Make an API call to get the list of stations for the selected division
        const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${value}`);
  
        // Update the "stations" state variable with the stations returned by the API call
        setStations(stationsResponse.data);
      } catch (error) {
        console.log(error);
      }
    }
  };


  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitted(true)

    toast.success("User updated successfully!", {
      onClose: () => navigate('/user_list') // Redirect to the home page when the toast is closed
    });

    try {
      const response = await axiosInstance.put(
        `/api/users/${id}`,
        formValues
      );
      // do something with the response, such as displaying a success message
      console.log(response.data);
    } catch (error) {
      // handle the error, such as displaying an error message
      console.log(error);
    }
  };

  // Load data from server and reinitialize student form
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `/api/users/${id}`
        );
        setFormValues(response.data);

      // If the report has a region, fetch the corresponding divisions
      if (response.data.region) {
        const divisionsResponse = await axiosInstance.get(`/api/divisions/divisionList/${response.data.region}`);
        setDivisions(divisionsResponse.data);
        console.log(divisionsResponse.data);    
    }
    
    // If the report has a division, fetch the corresponding stations
    if (response.data.division) {
      const stationsResponse = await axiosInstance.get(`/api/stations/station_list/${response.data.division}`);
      setStations(stationsResponse.data);
    }

      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return report form
  return (
  
    <Container>
    <ToastContainer />
      <form className="submit-form" onSubmit={handleSubmit}>
        <h1>Update User</h1>

        <Row>
          <Col>
            <div className="form-group">
              <label>First Name:</label>
              <input
                className="form-control"
                type="text"
                name="first_name"
                value={formValues.first_name || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                className="form-control"
                type="text"
                name="last_name"
                value={formValues.last_name || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label>Other Names: </label>
              <input
                className="form-control"
                type="text"
                name="other_names"
                value={formValues.other_names || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <label>Gender:</label>
              <input
                className="form-control"
                type="text"
                name="gender"
                value={formValues.gender || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label>Email:</label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={formValues.email || ""}
                onChange={handleChange}
              />

              <br />
            </div>
          </Col>
        </Row>

        <div className="form-group">
          <label>Phone Number: </label>
          <input
            className="form-control"
            type="text"
            name="phone_number"
            value={formValues.phone_number || ""}
            onChange={handleChange}
          />
          <br />
        </div>

        <div className="form-group">
          <label>Station Name: </label>
          <select
            className="form-control"
            name="station_name"
            value={formValues.station_name}
            onChange={handleChange}
          >
            <option value="">Select a Station</option>
            {stations.map((station, index) => (
              <option key={index} value={station.station_name}>
                {station.station_name}
              </option>
            ))}
          </select>
          <br />
        </div>

        <div className="form-group">
          <label>Division:</label>

              <select
                className="form-control"
                id="division"
                value={formValues.division}
                onChange={handleStationListnChange}
                name="division"
              >
                <option value="">Select a division</option>
                    {divisions.map((division, index) => (
                      <option key={index} value={division.div_id}>
                        {division.div_name}
                      </option>
                    ))}
              </select>
          <br />
        </div>

        <div className="form-group">
          <label>Region:</label>

           <select
                className="form-control"
                id="region"
                value={formValues.region || ""}
                onChange={handleDivisionListnChange}
                name="region"
              >
                <option value={0}>Choose a Region</option>
                <option value={1}>Region 1 Barima-Waini</option>
                <option value={2}>Region 2 Pomeroon-Supenaam</option>
                <option value={3}>Region 3 Essequibo Islands-West Demerara</option>
                <option value={4}>Region 4 Demerara-Mahaica</option>
                <option value={5}>Region 5 Mahaica-Berbice</option>
                <option value={6}>Region 6 East Berbice-Corentyne</option>
                <option value={7}>Region 7 Cuyuni-Mazaruni</option>
                <option value={8}>Region 8 Potaro-Siparuni</option>
                <option value={9}>Region 9 Upper Takutu-Upper Essequibo</option>
                <option value={10}>Region 10 Upper Demerara-Berbice</option>
              </select>
          <br />
        </div>

        <div className="form-group">
          <label>User Role:</label>
          <select
            className="form-control"
            name="user_role"
            value={formValues.user_role || ""}
            onChange={handleChange}
          >
            <option >Choose a Role</option>
                <option >Admin</option>
                <option >Normal User</option>
                <option >Data Entry</option>
              </select>
          <br />
        </div>

        <button type="submit" className="btn btn-success">Submit</button>
      </form>
    </Container>
  );
};

// Export EditUser Component
export default EditUser;
