import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../../services/axiosInstance';

const AddSuggestion = (props) => {
  const navigate = useNavigate(); // Call useNavigate to get the navigate function
  
  const [suggestion, setSuggestion] = useState({
      id: null,
      name: "",
      email: "",
      suggestion_box: "",

      submitted: false
    });
  

  const saveSuggestion = () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    axiosInstance.post('/api/suggestions', suggestion, config)
      .then(response => {
        setSuggestion({
          ...response.data,
          submitted: true
        });

        toast.success("Suggestion sent successfully!", {
          onClose: () => navigate('/') // Redirect to the home page when the toast is closed
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
        toast.error("Error sending the suggestion."); // Display an error toast
      });
  }

   // Define a function to handle the onChange for each field
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSuggestion({ ...suggestion, [name]: value });
  };
   
    return (
      <div className="submit-form">
      <ToastContainer />
        <div>
            <h1>Send a Suggestion</h1>
            <Row>
              <Col>
              <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={suggestion.name}
                onChange={handleInputChange}
                name="name"
              />
            </div>
              </Col>

              <Col>
              <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                value={suggestion.email}
                onChange={handleInputChange}
                name="email"
              />
            </div>
              </Col>
            </Row>
      

            <div className="form-group">
              <label htmlFor="suggestion_box">
              Suggestion
              </label>
              <textarea
                type="text"
                className="form-control mb3"
                id="suggestion_box"
                value={suggestion.suggestion_box}
                onChange={handleInputChange}
                name="suggestion_box"
              />
            </div>


            <button onClick={saveSuggestion} className="btn btn-success">
              Submit
            </button>
          </div>
      </div>
    );
  
}
export default AddSuggestion;
