import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY;

const instance = axios.create({
 baseURL: 'https://wxs.oav.mybluehost.me/reporting_app/',
 //baseURL: 'http://localhost:8084',
  headers: {
    'X-API-Key': apiKey
  }
});

export default instance;
